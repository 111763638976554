import styled from "styled-components";
import { DateCalendar } from "@mui/x-date-pickers";

// eslint-disable-next-line import/prefer-default-export
export const StyledDateCalendar = styled(DateCalendar)`
  display: flex;
  align-self: flex-start;
  border-radius: 0.5rem;
  border: 0.15rem solid rgb(244, 244, 244);
  background: rgb(244, 244, 244);

  .MuiPickersToolbar-root {
    background-color: rgb(120, 203, 255);
  }

  .MuiPickersDay-today {
    border: 0.1rem solid #ffcf84;
  }

  .Mui-selected {
    background-color: rgb(120, 203, 255);

    &:hover {
      background-color: rgb(120, 203, 255);
    }

    &:focus {
      background-color: rgb(120, 203, 255);
    }
  }

  .MuiPickersDay-dayWithMargin {
    &:hover {
      background-color: #ffcf84;
    }
  }
`;
