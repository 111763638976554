import {
  FoodItemKey,
  Menu,
  MenuItemBasicWithSides,
  MenuSection,
} from "client/jspPlatformExperiment";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, merge } from "lodash";

export type TypeDisplayMenuItems = Record<string, DisplayMenuItem>;

export type TypeDisplayMenuSections = Record<string, DisplayMenuSection>;

export interface DisplayMenuItem
  extends Omit<MenuItemBasicWithSides, "sides" | "food_item"> {
  food_item?: FoodItemKey;
  sides: TypeDisplayMenuItems;
}

interface DisplayMenuSection
  extends Omit<MenuSection, "section_rank" | "items"> {
  original_name?: string;
  items: TypeDisplayMenuItems;
}

export const defaultMenuItemDisplay = (): DisplayMenuItem => ({
  id: 0,
  food_id: 0,
  display_name: "",
  cap: 0,
  portion_cost: 0,
  portion_price: 0,
  sides: {},
});

export const defaultMenuSectionDisplay = (): DisplayMenuSection => ({
  menu_id: 0,
  name: "",
  items: { [uuidv4()]: defaultMenuItemDisplay() },
});

export enum MenuContentActions {
  LOAD_FROM_MENU_DATA = "LOAD_FROM_MENU_DATA",
  ADD_SECTION = "ADD_SECTION",
  REMOVE_SECTION = "REMOVE_SECTION",
  UPDATE_SECTION = "UPDATE_SECTION",
  MOVE_SECTION_UP = "MOVE_SECTION_UP",
  MOVE_SECTION_DOWN = "MOVE_SECTION_DOWN",
  ADD_ITEM = "ADD_ITEM",
  REMOVE_ITEM = "REMOVE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  MOVE_ITEM_UP = "MOVE_ITEM_UP",
  MOVE_ITEM_DOWN = "MOVE_ITEM_DOWN",
  ADD_SIDE = "ADD_SIDE",
  REMOVE_SIDE = "REMOVE_SIDE",
  UPDATE_SIDE = "UPDATE_SIDE",
  MOVE_SIDE_UP = "MOVE_SIDE_UP",
  MOVE_SIDE_DOWN = "MOVE_SIDE_DOWN",
}

export interface MenuContentAction {
  type: MenuContentActions;
  sectionUUID?: string;
  newSectionFields?: Partial<DisplayMenuSection>;
  itemMainUUID?: string;
  itemSideUUID?: string;
  newItemFields?: Partial<DisplayMenuItem>;
  menuData?: Menu;
}

export const menuContentsReducer = (
  menuContents: TypeDisplayMenuSections,
  action: MenuContentAction,
): TypeDisplayMenuSections => {
  switch (action.type) {
    case MenuContentActions.LOAD_FROM_MENU_DATA: {
      return Object.fromEntries(
        action.menuData!.sections.map((section) => [
          uuidv4(),
          {
            ...section,
            original_name: section.name,
            menu_id: action.menuData!.id,
            items: Object.fromEntries(
              section.items.map((item) => [
                uuidv4(),
                {
                  ...item,
                  sides: Object.fromEntries(
                    item.sides!.map((side) => [
                      uuidv4(),
                      { ...side, sides: {} },
                    ]),
                  ),
                },
              ]),
            ),
          },
        ]),
      );
    }
    case MenuContentActions.ADD_SECTION: {
      return {
        ...menuContents,
        [uuidv4()]: defaultMenuSectionDisplay(),
      };
    }
    case MenuContentActions.REMOVE_SECTION: {
      const { [action.sectionUUID!]: remove, ...rest } = menuContents;
      return rest;
    }
    case MenuContentActions.UPDATE_SECTION: {
      return merge({}, menuContents, {
        [action.sectionUUID!]: action.newSectionFields,
      });
    }
    case MenuContentActions.MOVE_SECTION_UP: {
      const keys = Object.keys(menuContents);
      const currentIdx = keys.indexOf(action.sectionUUID!);
      if (currentIdx === 0) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx - 1, 0, action.sectionUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      return keys.reduce((acc, key) => {
        acc[key] = menuContents[key];
        return acc;
      }, {} as TypeDisplayMenuSections);
    }
    case MenuContentActions.MOVE_SECTION_DOWN: {
      const keys = Object.keys(menuContents);
      const currentIdx = keys.indexOf(action.sectionUUID!);
      if (currentIdx === keys.length - 1) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx + 1, 0, action.sectionUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      return keys.reduce((acc, key) => {
        acc[key] = menuContents[key];
        return acc;
      }, {} as TypeDisplayMenuSections);
    }
    case MenuContentActions.ADD_ITEM: {
      return merge({}, menuContents, {
        [action.sectionUUID!]: {
          items: {
            [uuidv4()]: defaultMenuItemDisplay(),
          },
        },
      });
    }
    case MenuContentActions.REMOVE_ITEM: {
      const updateSection = menuContents[action.sectionUUID!];
      const { [action.itemMainUUID!]: remove, ...restItemsMain } =
        updateSection.items;
      const newMenuContents = cloneDeep(menuContents);
      newMenuContents[action.sectionUUID!].items = restItemsMain;
      return newMenuContents;
    }
    case MenuContentActions.UPDATE_ITEM: {
      return merge({}, menuContents, {
        [action.sectionUUID!]: {
          items: {
            [action.itemMainUUID!]: action.newItemFields,
          },
        },
      });
    }
    case MenuContentActions.MOVE_ITEM_UP: {
      const updateSection = menuContents[action.sectionUUID!];
      const keys = Object.keys(updateSection.items);
      const currentIdx = keys.indexOf(action.itemMainUUID!);
      if (currentIdx === 0) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx - 1, 0, action.itemMainUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      const newSectionMainItems = keys.reduce((acc, key) => {
        acc[key] = updateSection.items[key];
        return acc;
      }, {} as TypeDisplayMenuItems);

      return {
        ...menuContents,
        [action.sectionUUID!]: {
          ...menuContents[action.sectionUUID!],
          items: newSectionMainItems,
        },
      };
    }
    case MenuContentActions.MOVE_ITEM_DOWN: {
      const updateSection = menuContents[action.sectionUUID!];
      const keys = Object.keys(updateSection.items);
      const currentIdx = keys.indexOf(action.itemMainUUID!);
      if (currentIdx === keys.length - 1) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx + 1, 0, action.itemMainUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      return {
        ...menuContents,
        [action.sectionUUID!]: {
          ...menuContents[action.sectionUUID!],
          items: keys.reduce((acc, key) => {
            acc[key] = updateSection.items[key];
            return acc;
          }, {} as TypeDisplayMenuItems),
        },
      };
    }
    case MenuContentActions.ADD_SIDE: {
      return merge({}, menuContents, {
        [action.sectionUUID!]: {
          items: {
            [action.itemMainUUID!]: {
              sides: {
                [uuidv4()]: defaultMenuItemDisplay(),
              },
            },
          },
        },
      });
    }
    case MenuContentActions.REMOVE_SIDE: {
      const updateSection = menuContents[action.sectionUUID!];
      const updateItemMain = updateSection.items[action.itemMainUUID!];
      const { [action.itemSideUUID!]: remove, ...restItemsSide } =
        updateItemMain.sides;
      const newMenuContents = cloneDeep(menuContents);
      newMenuContents[action.sectionUUID!].items[action.itemMainUUID!].sides =
        restItemsSide;
      return newMenuContents;
    }
    case MenuContentActions.UPDATE_SIDE: {
      return merge({}, menuContents, {
        [action.sectionUUID!]: {
          items: {
            [action.itemMainUUID!]: {
              sides: {
                [action.itemSideUUID!]: action.newItemFields,
              },
            },
          },
        },
      });
    }
    case MenuContentActions.MOVE_SIDE_UP: {
      const updateSection = menuContents[action.sectionUUID!];
      const updateItemMain = updateSection.items[action.itemMainUUID!];
      const keys = Object.keys(updateItemMain.sides);
      const currentIdx = keys.indexOf(action.itemSideUUID!);
      if (currentIdx === 0) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx - 1, 0, action.itemSideUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      const newSides = keys.reduce((acc, key) => {
        acc[key] = updateItemMain.sides[key];
        return acc;
      }, {} as TypeDisplayMenuItems);
      return {
        ...menuContents,
        [action.sectionUUID!]: {
          ...menuContents[action.sectionUUID!],
          items: {
            ...menuContents[action.sectionUUID!].items,
            [action.itemMainUUID!]: {
              ...menuContents[action.sectionUUID!].items[action.itemMainUUID!],
              sides: newSides,
            },
          },
        },
      };
    }
    case MenuContentActions.MOVE_SIDE_DOWN: {
      const updateSection = menuContents[action.sectionUUID!];
      const updateItemMain = updateSection.items[action.itemMainUUID!];
      const keys = Object.keys(updateItemMain.sides);
      const currentIdx = keys.indexOf(action.itemSideUUID!);
      if (currentIdx === keys.length - 1) {
        return menuContents;
      }
      // Manipulate the key ordering
      keys.splice(currentIdx, 1); // Remove the key
      keys.splice(currentIdx + 1, 0, action.itemSideUUID!); // Insert the key at the new position
      // Construct a new section items record with new item order
      return {
        ...menuContents,
        [action.sectionUUID!]: {
          ...menuContents[action.sectionUUID!],
          items: {
            ...menuContents[action.sectionUUID!].items,
            [action.itemMainUUID!]: {
              ...menuContents[action.sectionUUID!].items[action.itemMainUUID!],
              sides: keys.reduce((acc, key) => {
                acc[key] = updateItemMain.sides[key];
                return acc;
              }, {} as TypeDisplayMenuItems),
            },
          },
        },
      };
    }
    default: {
      return menuContents;
    }
  }
};
