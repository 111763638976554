import {
  DisplayMenuLoop,
  DisplayMenuLoopSpecialDateBasic,
} from "models/menu/menuLoop";
import { MenuLoop } from "client/jspPlatformExperiment";
import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line import/prefer-default-export
export const getDisplayMenuLoopFromMenuLoopData = (
  menuLoop: MenuLoop,
): DisplayMenuLoop => {
  return {
    ...menuLoop,
    skip_dates: menuLoop.skip_dates?.reduce<
      Record<string, DisplayMenuLoopSpecialDateBasic>
    >(
      (allSpecialDate, specialDate) => ({
        ...allSpecialDate,
        [uuidv4()]: { ...specialDate },
      }),
      {},
    ),
  };
};
