import { RootState } from "store/index";
import { isFoodIngredient, isFoodRecipe } from "models/food/item/foodItem";
import { FoodRecipeKey } from "client/jspPlatformExperiment";
import { createSelector } from "@reduxjs/toolkit";

const idToFoodItemOptionRecords = (state: RootState) => {
  return state.foodItemOptions.data;
};

const isFoodOptionsLoading = (state: RootState) => {
  return state.foodItemOptions.isLoading;
};

const foodItemOptions = createSelector([idToFoodItemOptionRecords], (data) =>
  Object.values(data).sort(
    (a, b) => Number(isFoodRecipe(a)) - Number(isFoodRecipe(b)),
  ),
);

const foodIngredientOptions = createSelector([foodItemOptions], (data) =>
  data.filter((value) => isFoodIngredient(value)),
);

const foodRecipeOptions = createSelector([foodItemOptions], (data) =>
  data
    .filter((value) => isFoodRecipe(value))
    .map((value): FoodRecipeKey => {
      const recipe = value.food_recipes![0];
      return {
        id: recipe.id,
        name: recipe.name,
        food_id: value.id,
        food_name: value.food_name,
      };
    }),
);

const foodDishOptions = createSelector([foodItemOptions], (data) =>
  data.filter((value) => !!value.portion_size && value.portion_size > 0),
);

const foodItemOptionsUpdatedAt = (state: RootState) =>
  state.foodItemOptions.updatedAt;

export default {
  idToFoodItemOptionRecords,
  isFoodOptionsLoading,
  foodItemOptions,
  foodIngredientOptions,
  foodRecipeOptions,
  foodDishOptions,
  foodItemOptionsUpdatedAt,
};
