import React, { FC, useCallback } from "react";
import { MenuItemGroupEditBoxProps } from "components/menu/ui/MenuSectionEditBox";
import { Stack, Tooltip } from "@mui/material";
import MenuItemEditBox from "components/menu/ui/MenuItemEditBox";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIconButton from "components/common/ui/CircleIconButton";
import PillButton from "components/common/ui/PillButton";
import { MenuContentActions } from "models/menu/menuContentsReducer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SoftSquareIconButton from "components/common/ui/SoftSquareIconButton";

const BorderBox = styled(Box)`
  border: 0.1rem solid #eee;
  padding: 0.5rem;
  border-radius: 0.5rem;
`;

const MainItemTitle = styled(Typography)`
  padding: 0.5rem;
  align-self: center;
  font-weight: 100;
  font-size: 1.5rem;
  color: #a0aab4;
`;

export interface MenuItemMainEditBoxProps extends MenuItemGroupEditBoxProps {
  displayItemMainUUID: string;
}

const MenuItemMainEditBox: FC<MenuItemMainEditBoxProps> = ({
  foodItemOptions,
  displayMenuSectionUUID,
  displayItemMainUUID,
  menuContents,
  dispatchMenuContents,
}) => {
  /**
   * Add a new side dish to the main dish
   */
  const handleAddSide = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.ADD_SIDE,
      sectionUUID: displayMenuSectionUUID,
      itemMainUUID: displayItemMainUUID,
    });
  }, [dispatchMenuContents, displayItemMainUUID, displayMenuSectionUUID]);

  /**
   * Remove a side dish from the main dish
   */
  const handleRemoveSide = useCallback(
    (displayItemSideUUID: string) => () => {
      dispatchMenuContents({
        type: MenuContentActions.REMOVE_SIDE,
        sectionUUID: displayMenuSectionUUID,
        itemMainUUID: displayItemMainUUID,
        itemSideUUID: displayItemSideUUID,
      });
    },
    [dispatchMenuContents, displayItemMainUUID, displayMenuSectionUUID],
  );

  /**
   * Remove a main item from the menu section
   */
  const handleRemoveItemMain = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.REMOVE_ITEM,
      sectionUUID: displayMenuSectionUUID,
      itemMainUUID: displayItemMainUUID,
    });
  }, [dispatchMenuContents, displayItemMainUUID, displayMenuSectionUUID]);

  /**
   * Move a main item up in the menu section
   */
  const handleMoveItemMainUp = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.MOVE_ITEM_UP,
      sectionUUID: displayMenuSectionUUID,
      itemMainUUID: displayItemMainUUID,
    });
  }, [dispatchMenuContents, displayItemMainUUID, displayMenuSectionUUID]);

  /**
   * Move a main item down in the menu section
   */
  const handleMoveItemMainDown = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.MOVE_ITEM_DOWN,
      sectionUUID: displayMenuSectionUUID,
      itemMainUUID: displayItemMainUUID,
    });
  }, [dispatchMenuContents, displayItemMainUUID, displayMenuSectionUUID]);

  return (
    <BorderBox>
      <Box display="flex" paddingBottom={1}>
        <Box display="flex" padding={1}>
          <Box>
            <Tooltip title="Move this item and it's sides up">
              <SoftSquareIconButton onClick={handleMoveItemMainUp}>
                <KeyboardArrowUpIcon />
              </SoftSquareIconButton>
            </Tooltip>
          </Box>
          <Box paddingLeft={1}>
            <Tooltip title="Move this item and it's sides down">
              <SoftSquareIconButton onClick={handleMoveItemMainDown}>
                <KeyboardArrowDownIcon />
              </SoftSquareIconButton>
            </Tooltip>
          </Box>
        </Box>
        <MainItemTitle>
          {(menuContents[displayMenuSectionUUID].name ?? "") !== ""
            ? menuContents[displayMenuSectionUUID].name
            : `Section ${
                Object.keys(menuContents).findIndex(
                  (key) => key === displayMenuSectionUUID,
                ) + 1
              } - Item`}
          {` ${
            Object.keys(menuContents[displayMenuSectionUUID].items).findIndex(
              (key) => key === displayItemMainUUID,
            ) + 1
          }`}
        </MainItemTitle>
        <Box padding={1}>
          <Tooltip title="Remove this item and it's sides">
            <CircleIconButton positive danger onClick={handleRemoveItemMain}>
              <DeleteIcon />
            </CircleIconButton>
          </Tooltip>
        </Box>
      </Box>
      <Stack spacing={1}>
        <MenuItemEditBox
          displayMenuSectionUUID={displayMenuSectionUUID}
          displayItemMainUUID={displayItemMainUUID}
          menuContents={menuContents}
          dispatchMenuContents={dispatchMenuContents}
          foodItemOptions={foodItemOptions}
        />
        {Object.keys(
          menuContents[displayMenuSectionUUID].items[displayItemMainUUID].sides,
        ).map((itemSideUUID, itemSideIdx) => (
          <MenuItemEditBox
            key={itemSideUUID}
            displayMenuSectionUUID={displayMenuSectionUUID}
            displayItemMainUUID={displayItemMainUUID}
            displayItemSideUUID={itemSideUUID}
            menuContents={menuContents}
            dispatchMenuContents={dispatchMenuContents}
            label={`Side ${itemSideIdx + 1}`}
            onDelete={handleRemoveSide(itemSideUUID)}
            foodItemOptions={foodItemOptions}
          />
        ))}
      </Stack>
      <PillButton text="Add side" onClick={handleAddSide} />
    </BorderBox>
  );
};

export default MenuItemMainEditBox;
