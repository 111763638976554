import React, { FC, useCallback, useEffect } from "react";
import { Container, Grid, Tooltip } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styled, { css } from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import jspPlatformApi from "client/portals";
import Typography from "@mui/material/Typography";
import MenuViewBox from "components/menu/ui/common/MenuViewBox";
import { StyledDateCalendar } from "components/common/ui/Calendars";
import CommonButton from "components/common/ui/CommonButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { usePromptSnackBar } from "components/common/ui/PromptSnackBarProvider";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { MenusOnDateActions } from "store/slices/menu/menusOnDateSlice";
import menusOnDateSelector from "store/selectors/menu/menusOnDateSelector";
import ColorCircularProgress from "components/common/ui/ColorCircularProgress";
import Box from "@mui/material/Box";

const SelectedDateHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 100;
  padding-left: 0.5rem;
`;

interface MenuPublishPageProps {}

const MenuPublishPage: FC<MenuPublishPageProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const dailyMenus = useAppSelector(menusOnDateSelector.menusOnDate);
  const dailyMenusLoading = useAppSelector(
    menusOnDateSelector.isMenusOnDateLoading,
  );
  const promptSnackBar = usePromptSnackBar();

  /**
   * Make sure to load menus at first time opening this page
   */
  useEffect(() => {
    const queryDate = searchParams.get("date");
    if (!queryDate) {
      setSearchParams(
        { date: dayjs().format("YYYY-MM-DD") },
        { replace: true },
      );
      return () => {};
    }
    dispatch(MenusOnDateActions.setMenus([]));
    const promise = dispatch(
      MenusOnDateActions.fetchMenusOnDate(dayjs(queryDate)),
    );
    // Only keep one active instance to call JSA server
    return () => {
      promise.abort("Kill previous JSA server call");
    };
  }, [dispatch, searchParams, setSearchParams]);

  /**
   * Handle select date changes
   */
  const handleSelectedDateChange = useCallback(
    (newValue: Dayjs) => {
      setSearchParams(
        { date: newValue.format("YYYY-MM-DD") },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  /**
   * Handle download menu CSV button click
   */
  const handleDownloadMenusCSV = useCallback(() => {
    const searchingDate: string | null = searchParams.get("date");
    if (searchingDate) {
      jspPlatformApi()
        .menu.downloadDailyMenusMenuDailyQueryDateDownloadGet({
          queryDate: searchingDate,
        })
        .then((response) => {
          // Trigger CSV download
          const url = window.URL.createObjectURL(
            new Blob([response as Blob], { type: "text/csv" }),
          );
          const alink = document.createElement("a");
          alink.href = url;
          alink.download =
            `menus+ingredients on ${searchingDate} ` +
            `downloaded at ${dayjs().toISOString()}.csv`;
          document.body.appendChild(alink);
          alink.click();
          alink.remove();
        })
        .catch((err) => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: `Download error. ${err}`,
            severity: "error",
          });
        });
    }
  }, [promptSnackBar, searchParams]);

  return (
    <Container>
      <BottomSpacedPageHeader>Published Menus</BottomSpacedPageHeader>
      <Grid container spacing={1} columns={24}>
        <Grid item lg={7}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateCalendar
              views={["year", "month", "day"]}
              value={dayjs(searchParams.get("date")) ?? dayjs()}
              onChange={handleSelectedDateChange}
            />
          </LocalizationProvider>
          {searchParams.get("date") && dailyMenus.length > 0 ? (
            <Tooltip title="Download menus and ingredients file on the selected date">
              <CommonButton
                positive
                startIcon={<FileDownloadIcon />}
                text="Menus + Ingredients"
                onClick={handleDownloadMenusCSV}
              />
            </Tooltip>
          ) : null}
        </Grid>
        <Grid item lg={17} xs={24}>
          <SelectedDateHeader>
            {searchParams.get("date")
              ? dayjs(searchParams.get("date")).format("dddd MMMM DD, YYYY ")
              : ""}
          </SelectedDateHeader>
          <Grid container>
            {dailyMenusLoading ? (
              <>
                <Box sx={{ display: "flex" }}>
                  <ColorCircularProgress color={css`rgb(120, 203, 255)`} />
                </Box>
                <Typography>Loading ...</Typography>
              </>
            ) : (
              dailyMenus.map((menu) => (
                <Grid key={`${menu.name ?? ""} ${menu.id}`} item md={6} xs={24}>
                  <MenuViewBox menu={menu} />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenuPublishPage;
