import { Grid } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FoodItemSupplierInfo } from "client/jspPlatformExperiment";
import SpacedTextInput from "components/common/ui/SpacedTextInput";
import {
  getFoodSupplierFieldAdornment,
  getFoodSupplierFieldValue,
  supplierFields as displaySupplierFields,
} from "components/food/ui/common/FoodSupplierViewSection";

interface FoodSupplierEditSectionProps {
  supplierInfo: FoodItemSupplierInfo;
  setSupplierInfo: Dispatch<SetStateAction<FoodItemSupplierInfo>>;
}

const supplierFields: { label: string; name: keyof FoodItemSupplierInfo }[] = [
  ...displaySupplierFields.slice(
    0,
    displaySupplierFields.findIndex((elm) => elm.name === "url"),
  ),
  { label: "Supplier Unit Size", name: "supplier_unit_size" },
  { label: "Supplier Unit Size UOM", name: "supplier_unit_size_uom" },
  ...displaySupplierFields.slice(
    displaySupplierFields.findIndex((elm) => elm.name === "url"),
  ),
];

const FoodSupplierEditSection: FC<FoodSupplierEditSectionProps> = ({
  supplierInfo,
  setSupplierInfo,
}) => {
  const [displayPackPrice, setDisplayPackPrice] = useState<string | number>(
    supplierInfo.pack_price ?? "",
  );

  /**
   *  Make sure the display price is loaded
   */
  useEffect(() => {
    if (displayPackPrice === "" && !!supplierInfo.pack_price) {
      setDisplayPackPrice(supplierInfo.pack_price / 100);
    }
  }, [displayPackPrice, supplierInfo.pack_price]);

  /**
   * Handle the supplier field change
   */
  const handleSupplierInfoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let targetValue: string | number = event.target.value;

      // Adjust the value to store if needed
      switch (event.target.name) {
        case "pack_price":
          setDisplayPackPrice(targetValue);
          targetValue = Math.round((Number(targetValue) ?? 0.0) * 100);
          break;
        default:
          break;
      }

      // Changing action
      setSupplierInfo({
        ...supplierInfo,
        [event.target.name]: targetValue,
      });
    },
    [setSupplierInfo, supplierInfo],
  );

  return (
    <Grid container spacing={1}>
      {supplierFields.map((supplierTextFieldProps) => (
        <Grid item key={supplierTextFieldProps.name} xs={12} md={4} lg={3}>
          <SpacedTextInput
            InputLabelProps={{ shrink: true }}
            onChange={handleSupplierInfoChange}
            label={supplierTextFieldProps.label}
            name={supplierTextFieldProps.name}
            value={
              supplierTextFieldProps.name === "pack_price"
                ? displayPackPrice
                : getFoodSupplierFieldValue(
                    supplierTextFieldProps.name,
                    supplierInfo,
                  )
            }
            error={
              supplierTextFieldProps.name === "pack_size" ||
              supplierTextFieldProps.name === "unit_size" ||
              supplierTextFieldProps.name === "supplier_unit_size"
                ? (getFoodSupplierFieldValue(
                    supplierTextFieldProps.name,
                    supplierInfo,
                  ) as number) <= 0
                : false
            }
            InputProps={getFoodSupplierFieldAdornment(
              supplierTextFieldProps.name,
              supplierInfo,
            )}
            required
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodSupplierEditSection;
