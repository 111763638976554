import { RootState } from "store/index";

const foodItemFull = (state: RootState) => state.foodItemDetail.foodItemFull;

const foodItemBasic = (state: RootState) => state.foodItemDetail.foodItem;

const foodTags = (state: RootState) => state.foodItemDetail.foodTags;

const foodNutritionRecords = (state: RootState) =>
  state.foodItemDetail.foodNutritionRecords;

const foodRecipes = (state: RootState) => state.foodItemDetail.foodRecipes;

const foodSuppliers = (state: RootState) => state.foodItemDetail.foodSuppliers;

const foodIsLoading = (state: RootState) => state.foodItemDetail.isLoading;

const foodApprovalIsChanging = (state: RootState) =>
  state.foodItemDetail.isApprovalChanging;

const foodError = (state: RootState) => state.foodItemDetail.error;

export default {
  foodItemFull,
  foodItemBasic,
  foodTags,
  foodNutritionRecords,
  foodRecipes,
  foodSuppliers,
  foodIsLoading,
  foodApprovalIsChanging,
  foodError,
};
