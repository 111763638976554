import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { SpacedAutocompleteInput } from "components/common/ui/SpacedTextInput";
import { Grid, Tooltip } from "@mui/material";
import EditSection from "components/common/ui/EditSection";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftSquareIconButton from "components/common/ui/SoftSquareIconButton";
import PillButton from "components/common/ui/PillButton";
import { MenuContentActions } from "models/menu/menuContentsReducer";
import MenuItemMainEditBox from "components/menu/ui/MenuItemMainEditBox";
import { MenuContentEditSectionProps } from "components/menu/ui/MenuContentsEditSection";
import { MenuSectionNameOptions } from "models/menu/constants/sectionName";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIconButton from "components/common/ui/CircleIconButton";

const MenuGroupNameTextField = styled(SpacedAutocompleteInput)`
  background: rgb(244, 244, 244);
  border-radius: 0.5rem;
` as typeof SpacedAutocompleteInput;

export interface MenuItemGroupEditBoxProps extends MenuContentEditSectionProps {
  displayMenuSectionUUID: string;
}

const MenuSectionEditBox: FC<MenuItemGroupEditBoxProps> = ({
  foodItemOptions,
  displayMenuSectionUUID,
  menuContents,
  dispatchMenuContents,
}) => {
  const [sectionName, setSectionName] = useState("");

  /**
   * Use effect to make sure the section name displaying are sync
   */
  useEffect(() => {
    setSectionName(menuContents[displayMenuSectionUUID].name);
  }, [displayMenuSectionUUID, menuContents]);

  /**
   * Add a new main item into the menu section
   */
  const handleAddItemMain = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.ADD_ITEM,
      sectionUUID: displayMenuSectionUUID,
    });
  }, [dispatchMenuContents, displayMenuSectionUUID]);

  /**
   * Remove the menu section
   */
  const handleRemoveMenuSection = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.REMOVE_SECTION,
      sectionUUID: displayMenuSectionUUID,
    });
  }, [dispatchMenuContents, displayMenuSectionUUID]);

  /**
   * Update the menu section
   */
  const handleUpdateMenuSection = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.UPDATE_SECTION,
      sectionUUID: displayMenuSectionUUID,
      newSectionFields: {
        name: sectionName,
      },
    });
  }, [dispatchMenuContents, displayMenuSectionUUID, sectionName]);

  /**
   * Move a menu section up
   */
  const handleMoveSectionUp = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.MOVE_SECTION_UP,
      sectionUUID: displayMenuSectionUUID,
    });
  }, [dispatchMenuContents, displayMenuSectionUUID]);

  /**
   * Move a menu section down
   */
  const handleMoveSectionDown = useCallback(() => {
    dispatchMenuContents({
      type: MenuContentActions.MOVE_SECTION_DOWN,
      sectionUUID: displayMenuSectionUUID,
    });
  }, [dispatchMenuContents, displayMenuSectionUUID]);

  /**
   * Handle change menu section name
   */
  const handleChangeMenuSectionName = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      if (!newValue) {
        setSectionName("");
      } else {
        setSectionName(newValue);
      }
    },
    [],
  );

  return (
    <EditSection>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <Box display="flex" sx={{ paddingRight: 1 }} alignSelf="center">
              <Box sx={{ paddingRight: 1 }}>
                <Tooltip title="Move this section and it's content up">
                  <CircleIconButton sizepx={46} onClick={handleMoveSectionUp}>
                    <KeyboardArrowUpIcon />
                  </CircleIconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Move this section and it's content down">
                  <CircleIconButton sizepx={46} onClick={handleMoveSectionDown}>
                    <KeyboardArrowDownIcon />
                  </CircleIconButton>
                </Tooltip>
              </Box>
            </Box>
            <MenuGroupNameTextField
              label="Menu Section Name"
              value={sectionName ?? ""}
              onBlur={handleUpdateMenuSection}
              onChange={handleChangeMenuSectionName}
              options={Object.values(MenuSectionNameOptions)}
              error={!sectionName}
              shrink
            />
            <Box sx={{ paddingLeft: 1 }}>
              <Tooltip title="Remove this section">
                <SoftSquareIconButton
                  positive
                  danger
                  sizepx={56}
                  onClick={handleRemoveMenuSection}
                >
                  <DeleteIcon />
                </SoftSquareIconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        {Object.keys(menuContents[displayMenuSectionUUID].items).map(
          (itemMainUUID) => (
            <Grid key={itemMainUUID} item xs={12}>
              <MenuItemMainEditBox
                displayItemMainUUID={itemMainUUID}
                displayMenuSectionUUID={displayMenuSectionUUID}
                menuContents={menuContents}
                dispatchMenuContents={dispatchMenuContents}
                foodItemOptions={foodItemOptions}
              />
            </Grid>
          ),
        )}
        <PillButton text="Add item above" onClick={handleAddItemMain} />
      </Grid>
    </EditSection>
  );
};

export default MenuSectionEditBox;
