import React, { FC, useCallback } from "react";
import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import menuLoopDetailSelector from "store/selectors/menu/menuLoopDetailSelector";
import { v4 as uuidv4 } from "uuid";
import { menuLoopActions } from "store/slices/menu/menuLoopDetailSlice";
import Box from "@mui/material/Box";
import PillButton from "components/common/ui/PillButton";
import MenuLoopSpecialDateRow from "components/menu/ui/menuLoop/MenuLoopSpecialDateRow";

interface MenuLoopSpecialDatesEditSectionProps {}

const MenuLoopSpecialDatesEditSection: FC<
  MenuLoopSpecialDatesEditSectionProps
> = () => {
  const editingMenuLoop = useAppSelector(
    menuLoopDetailSelector.editingMenuLoop,
  );
  const displaySpecialDates = useAppSelector(
    menuLoopDetailSelector.editingMenuLoopSpecialDates,
  );
  const dispatch = useAppDispatch();

  /**
   * Handle user add another special date row
   */
  const handleAddSpecialDateClick = useCallback(() => {
    dispatch(
      menuLoopActions.setMenuLoopDetail({
        ...editingMenuLoop,
        skip_dates: {
          ...displaySpecialDates,
          [uuidv4()]: {
            description: undefined,
            special_date: undefined,
          },
        },
      }),
    );
  }, [dispatch, displaySpecialDates, editingMenuLoop]);

  return (
    <>
      <Grid container spacing={1}>
        {Object.keys(displaySpecialDates ?? {}).map((dateKey) => (
          <MenuLoopSpecialDateRow key={dateKey} displayUUID={dateKey} />
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" sx={{ paddingTop: 1 }}>
        <PillButton
          text="Add special date"
          onClick={handleAddSpecialDateClick}
        />
      </Box>
    </>
  );
};

export default MenuLoopSpecialDatesEditSection;
