import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import menuLoopDetailSelector from "store/selectors/menu/menuLoopDetailSelector";
import { menuLoopActions } from "store/slices/menu/menuLoopDetailSlice";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Stack, Tooltip } from "@mui/material";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import CircleIconButton from "components/common/ui/CircleIconButton";
import CloseIcon from "@mui/icons-material/Close";
import SpacedTextInput, {
  SpacedDatePicker,
} from "components/common/ui/SpacedTextInput";

interface MenuLoopSpecialDateRowProps {
  displayUUID: string;
}

const MenuLoopSpecialDateRow: FC<MenuLoopSpecialDateRowProps> = ({
  displayUUID,
}) => {
  const editingMenuLoop = useAppSelector(
    menuLoopDetailSelector.editingMenuLoop,
  );
  const displaySpecialDates = useAppSelector(
    menuLoopDetailSelector.editingMenuLoopSpecialDates,
  );
  const editingSpecialDate = useAppSelector(
    menuLoopDetailSelector.getEditingMenuLoopSpecialDate,
  )(displayUUID);
  const dispatch = useAppDispatch();
  const [displayDescription, setDisplayDescription] = React.useState(
    editingSpecialDate?.description ?? "",
  );

  /**
   * Handle user delete special date row
   */
  const handleDeleteSpecialDateClick = useCallback(() => {
    const { [displayUUID]: deleteMenuLoop, ...rest } =
      displaySpecialDates ?? {};
    dispatch(
      menuLoopActions.setMenuLoopDetail({
        ...editingMenuLoop,
        skip_dates: rest,
      }),
    );
  }, [dispatch, displaySpecialDates, displayUUID, editingMenuLoop]);

  /**
   * Handle user change special date
   */
  const handleDateChange = useCallback(
    (newDate: Dayjs | null) => {
      dispatch(
        menuLoopActions.setMenuLoopDetail({
          ...editingMenuLoop,
          skip_dates: {
            ...displaySpecialDates,
            [displayUUID]: {
              ...editingSpecialDate,
              special_date: !newDate ? null : newDate.format("YYYY-MM-DD"),
            },
          },
        }),
      );
    },
    [
      dispatch,
      editingSpecialDate,
      displaySpecialDates,
      displayUUID,
      editingMenuLoop,
    ],
  );

  /**
   * Handle user change special date reason
   */
  const handleReasonChange = useCallback(
    (newDate: string | null) => {
      dispatch(
        menuLoopActions.setMenuLoopDetail({
          ...editingMenuLoop,
          skip_dates: {
            ...displaySpecialDates,
            [displayUUID]: {
              ...editingSpecialDate,
              description: newDate,
            },
          },
        }),
      );
    },
    [
      dispatch,
      editingSpecialDate,
      displaySpecialDates,
      displayUUID,
      editingMenuLoop,
    ],
  );

  /**
   * Validate if there is a duplicated special date
   */
  const hasDuplicatedSpecialDate = useCallback(
    () =>
      !!Object.entries(displaySpecialDates ?? {}).find(
        ([displayKey, specialDate]) =>
          displayKey !== displayUUID &&
          specialDate.special_date === editingSpecialDate?.special_date,
      ),
    [displaySpecialDates, displayUUID, editingSpecialDate?.special_date],
  );

  /**
   * Validate if special date out of loop date range
   */
  const isSpecialDateOutOfLoopingDateRange = useCallback(() => {
    const startDate = dayjs(editingMenuLoop?.start_date ?? null);
    const endDate = dayjs(editingMenuLoop?.end_date ?? null);
    const specialDate = dayjs(editingSpecialDate?.special_date ?? null);
    return !(
      !editingMenuLoop?.start_date ||
      !editingMenuLoop?.end_date ||
      !editingSpecialDate?.special_date ||
      (startDate <= specialDate && specialDate <= endDate)
    );
  }, [
    editingMenuLoop?.end_date,
    editingMenuLoop?.start_date,
    editingSpecialDate?.special_date,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12} sm={6} md={4}>
        <Stack spacing={1} direction="row">
          <AdaptiveRowHeader switchWidthInPixel={1}>
            <Tooltip title="Remove this row">
              <CircleIconButton
                positive
                danger
                sizepx={30}
                onClick={handleDeleteSpecialDateClick}
              >
                <CloseIcon />
              </CircleIconButton>
            </Tooltip>
          </AdaptiveRowHeader>
          <SpacedDatePicker
            label="Special date to skip"
            value={dayjs(editingSpecialDate?.special_date ?? null)}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                helperText:
                  `${hasDuplicatedSpecialDate() ? "Duplicated." : ""} ` +
                  `${isSpecialDateOutOfLoopingDateRange() ? "Out of looping date range." : ""} `,
                error:
                  !editingSpecialDate?.special_date || // prompt error when empty
                  isSpecialDateOutOfLoopingDateRange() ||
                  hasDuplicatedSpecialDate(),
              },
              field: {
                clearable: !!editingSpecialDate?.special_date,
                onClear: () => {
                  handleDateChange(null);
                },
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <SpacedTextInput
          label="Reason"
          value={displayDescription}
          onChange={(newValue) => {
            setDisplayDescription(newValue.target.value ?? "");
          }}
          onBlur={() => {
            handleReasonChange(displayDescription);
          }}
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default MenuLoopSpecialDateRow;
