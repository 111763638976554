import React, { FC, memo, useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  FoodItem,
  FoodItemKey,
  GordonFoodServiceOrderRecord,
  IngredientState,
  StorageTemperature,
} from "client/jspPlatformExperiment";
import CommonButton from "components/common/ui/CommonButton";
import SpacedTextField from "components/common/ui/SpacedTextField";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
} from "@mui/material";
import IngredientEditForms from "components/food/ui/IngredientEditForms";
import { actions as restockingActions } from "store/slices/inventory/restockingSlice";
import { actions } from "store/slices/foodItem/foodItemDetailSlice";
import { useAppDispatch } from "hooks/useReduxStore";
import dayjs from "dayjs";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import RelinkFoodActionForm from "components/inventory/ui/replenishment/RelinkFoodActionForm";
import { factorKG2Uom } from "utils/common/quantityUnitConversion";
import { RoundedItemBox } from "components/common/ui/RoundedItemBox";

export type GFSOrderItemActionState = {
  chooseAction?: string;
  actionMsg?: string;
};

interface GFSOrderItemActionBoxProps {
  displayKey: string;
  data: GordonFoodServiceOrderRecord;
  state: GFSOrderItemActionState;
  ingredientOptions: FoodItemKey[];
}

const GFSOrderItemActionBox: FC<GFSOrderItemActionBoxProps> = ({
  displayKey,
  data,
  state,
  ingredientOptions,
}) => {
  const dispatch = useAppDispatch();
  const [openFoodItemEdit, setOpenFoodItemEdit] = useState(false);

  const netWeightOffPercentage = useCallback(
    (GFSData: GordonFoodServiceOrderRecord) => {
      return Math.abs(
        (GFSData.pack_size * GFSData.unit_size) /
          factorKG2Uom(GFSData.unit_size_uom) /
          GFSData.net_weight -
          1,
      );
    },
    [],
  );

  const isSpecialCase = useCallback(
    (GFSData: GordonFoodServiceOrderRecord) => {
      return (
        netWeightOffPercentage(GFSData) > 0.35 &&
        GFSData.unit_size === GFSData.pack_size
      );
    },
    [netWeightOffPercentage],
  );

  const netWeightOffPercentageAdapted = useCallback(
    (GFSData: GordonFoodServiceOrderRecord) => {
      return Math.abs(
        (GFSData.pack_size * (isSpecialCase(GFSData) ? 1 : GFSData.unit_size)) /
          factorKG2Uom(GFSData.unit_size_uom) /
          GFSData.net_weight -
          1,
      );
    },
    [isSpecialCase],
  );

  const handleChooseActionChange = useCallback(
    (newValue: string | undefined) => () => {
      dispatch(
        restockingActions.updateAnActionItem({
          key: displayKey,
          newData: {
            ...state,
            chooseAction: newValue,
          },
        }),
      );
      if (newValue === "createNew") {
        dispatch(
          actions.setFoodItemDetail({
            id: 0,
            food_name: data.product_description,
            state: IngredientState.NONE,
            category: "other",
            storage_temp: StorageTemperature.FRIDGE,
            shelf_life: dayjs.duration({ days: 3 }).toISOString(),
            dispense_category: "",
            food_nutrition_records: [],
            food_suppliers: [
              {
                id: 0,
                food_id: 0,
                supplier_id: "GFS",
                product_code: data.item_code ?? "",
                product_name: data.product_description ?? "",
                pack_size: isSpecialCase(data) ? 1 : data.pack_size, // pack_size
                pack_price: Math.round(
                  (data.total_price / data.quantity_shipped) * 100,
                ), // in cents
                unit_size:
                  (data.unit_size * 1000) / factorKG2Uom(data.unit_size_uom),
                supplier_unit_size: data.unit_size,
                supplier_unit_size_uom: data.unit_size_uom,
                // url: ingData.url,
              },
            ], // Array<FoodItemSupplierInfo>;
          } as FoodItem),
        );
        setOpenFoodItemEdit(true);
      } else {
        setOpenFoodItemEdit(false);
      }
    },
    [data, dispatch, displayKey, isSpecialCase, state],
  );

  const creatNewSuccessCallback = useCallback(() => {
    setOpenFoodItemEdit(false);
    dispatch(
      restockingActions.updateAnActionItem({
        key: displayKey,
        newData: {
          chooseAction: "completed",
          actionMsg: "Created a new food item",
        },
      }),
    );
  }, [dispatch, displayKey]);

  const relinkSuccessCallback = useCallback(
    (msg: string) => {
      dispatch(
        restockingActions.updateAnActionItem({
          key: displayKey,
          newData: { chooseAction: "completed", actionMsg: msg },
        }),
      );
    },
    [dispatch, displayKey],
  );

  const actionForm = useMemo(() => {
    if (state.chooseAction === "relink") {
      return (
        <RelinkFoodActionForm
          newProductCode={data.item_code}
          ingredientOptions={ingredientOptions}
          onClose={handleChooseActionChange(undefined)}
          onRelink={relinkSuccessCallback}
        />
      );
    } else if (
      state.chooseAction === "createNew" ||
      state.chooseAction === "completed"
    ) {
      return (
        <AdaptiveRowHeader switchWidthInPixel={1}>
          <Chip label={state.actionMsg} />
        </AdaptiveRowHeader>
      );
    }
    return (
      <AdaptiveRowHeader switchWidthInPixel={1}>
        <Chip label="Skipped" onDelete={handleChooseActionChange(undefined)} />
      </AdaptiveRowHeader>
    );
  }, [
    state.chooseAction,
    state.actionMsg,
    handleChooseActionChange,
    data.item_code,
    ingredientOptions,
    relinkSuccessCallback,
  ]);

  return (
    <RoundedItemBox>
      <Grid container spacing={1} columns={24}>
        <Grid item xs={24} md={12} lg={11}>
          <SpacedTextField
            label="Product description"
            value={data.product_description}
            multiline
            gray
          />
        </Grid>
        <Grid item xs={9} sm={6} md={3}>
          <SpacedTextField label="Item code" value={data.item_code} gray />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <SpacedTextField label="Qty" value={data.quantity_shipped} gray />
        </Grid>
        <Grid item xs={9} sm={4} md={4} lg={2}>
          <SpacedTextField label="Pack size" value={data.pack_size} gray />
        </Grid>
        <Grid item xs={8} sm={4} md={3} lg={3}>
          <SpacedTextField
            label="Unit size"
            value={data.unit_size}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {data.unit_size_uom}
                </InputAdornment>
              ),
            }}
            gray
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3}>
          <SpacedTextField
            label="Total amount"
            value={
              data.quantity_shipped *
              (isSpecialCase(data) ? 1 : data.pack_size) *
              data.unit_size
            }
            error={netWeightOffPercentageAdapted(data) > 0.35}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {data.unit_size_uom}
                </InputAdornment>
              ),
            }}
            gray
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3}>
          <SpacedTextField
            label="Total price"
            value={Math.round(data.total_price * 100) / 100}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            gray
          />
        </Grid>
        {/* <Grid item xs={24} lg={13}>*/}
        <Grid item xs={24} md={16} lg={21}>
          <Box display="flex" justifyContent="flex-start">
            {state.chooseAction ? (
              actionForm
            ) : (
              <>
                <CommonButton
                  text="Skip"
                  onClick={handleChooseActionChange("skip")}
                  positive
                />
                <CommonButton
                  text="Link to food"
                  onClick={handleChooseActionChange("relink")}
                  positive
                />
                <CommonButton
                  text="Create new food"
                  onClick={handleChooseActionChange("createNew")}
                  positive
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={openFoodItemEdit}
        onClose={handleChooseActionChange(undefined)}
        scroll="paper"
      >
        <DialogTitle>Create New Food</DialogTitle>
        <DialogContent dividers>
          <IngredientEditForms saveButtonCallback={creatNewSuccessCallback} />
        </DialogContent>
        <DialogActions>
          <CommonButton
            onClick={handleChooseActionChange(undefined)}
            text="Cancel"
          />
        </DialogActions>
      </Dialog>
    </RoundedItemBox>
  );
};

export default memo(GFSOrderItemActionBox);
