import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const InfoLabel = styled(Typography)`
  text-align: center;
  min-width: 20px;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  color: rgb(124, 124, 124);
  background-color: rgb(244, 244, 244);
  border-radius: 1rem;
`;

export const RightAlignInfoLabelSubHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0.5rem 0 0 0.5rem;
  color: rgb(144, 144, 144);
  vertical-align: center;
`;
