import { FC, useCallback, useState } from "react";
import { FormControlLabel, Tooltip } from "@mui/material";
import styled from "styled-components";
import YesNoSwitch from "components/common/ui/YesNoSwitch";
import { FoodTag } from "client/jspPlatformExperiment";

interface FoodTagSwitchProps {
  foodTag: FoodTag;
  defaultState?: boolean;
  onChange: (changeTag: FoodTag) => void;
  hints?: string;
  viewOnly?: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 0 12px 0 6px;
`;

const FoodTagSwitch: FC<FoodTagSwitchProps> = ({
  foodTag,
  onChange,
  defaultState = false,
  hints = "",
  viewOnly = false,
}) => {
  const [isYes, setIsYes] = useState<boolean>(defaultState);

  const toggleOnOff = useCallback(() => {
    if (!viewOnly) {
      onChange(foodTag);
      setIsYes(!isYes);
    }
  }, [foodTag, isYes, onChange, viewOnly]);

  return (
    <Tooltip title={hints} placement="top">
      <StyledFormControlLabel
        control={<YesNoSwitch checked={isYes} onChange={toggleOnOff} />}
        label={foodTag.tag_name}
      />
    </Tooltip>
  );
};

export default FoodTagSwitch;
