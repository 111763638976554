import React, { FC, Suspense } from "react";

import { Provider } from "react-redux";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import AppRouter from "./components/AppRouter";
import store from "./store";
import "./i18n";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

interface AppProps {}

const App: FC<AppProps> = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <AppRouter />
      </Suspense>
    </Provider>
  );
};

export default App;
