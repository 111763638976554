import styled from "styled-components";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarProps,
} from "@mui/x-data-grid";
import { FC } from "react";

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  color: rgb(120, 203, 255);
`;

const CommonDownloadToolbar: FC<GridToolbarProps> = () => {
  return (
    <StyledGridToolbarContainer>
      <GridToolbarExport
        slotProps={{
          button: {
            color: "inherit",
          },
        }}
      />
    </StyledGridToolbarContainer>
  );
};

export default CommonDownloadToolbar;
