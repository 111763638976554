import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { Interceptors } from "./core/OpenAPI";
import { AxiosHttpRequest } from "./core/AxiosHttpRequest";

import { AuthenticationService } from "./services.gen";
import { DietProfileService } from "./services.gen";
import { FoodItemService } from "./services.gen";
import { FoodTagService } from "./services.gen";
import { IngredientService } from "./services.gen";
import { InventoryService } from "./services.gen";
import { MenuService } from "./services.gen";
import { OrderingService } from "./services.gen";
import { RecipeService } from "./services.gen";
import { UserService } from "./services.gen";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class JasperPlatformAPIClient {
  public readonly authentication: AuthenticationService;
  public readonly dietProfile: DietProfileService;
  public readonly foodItem: FoodItemService;
  public readonly foodTag: FoodTagService;
  public readonly ingredient: IngredientService;
  public readonly inventory: InventoryService;
  public readonly menu: MenuService;
  public readonly ordering: OrderingService;
  public readonly recipe: RecipeService;
  public readonly user: UserService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "",
      VERSION: config?.VERSION ?? "0.26.0",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
      interceptors: {
        request: config?.interceptors?.request ?? new Interceptors(),
        response: config?.interceptors?.response ?? new Interceptors(),
      },
    });

    this.authentication = new AuthenticationService(this.request);
    this.dietProfile = new DietProfileService(this.request);
    this.foodItem = new FoodItemService(this.request);
    this.foodTag = new FoodTagService(this.request);
    this.ingredient = new IngredientService(this.request);
    this.inventory = new InventoryService(this.request);
    this.menu = new MenuService(this.request);
    this.ordering = new OrderingService(this.request);
    this.recipe = new RecipeService(this.request);
    this.user = new UserService(this.request);
  }
}
