import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import dishPortionOrdersSelector from "store/selectors/order/dishPortionOrdersSelector";
import CommonButton from "components/common/ui/CommonButton";
import { actions } from "store/slices/inventory/foodPurchasingGuideSlice";
import {
  DishOrderPortions,
  PurchasingGuideItem,
} from "client/jspPlatformExperiment";
import DataGridListBox from "components/common/ui/DataGridListBox";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import foodPurchasingGuideSelector from "store/selectors/inventory/foodPurchasingGuideSelector";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Routes from "models/routes";
import { useNavigate } from "react-router-dom";
import EditSection from "components/common/ui/EditSection";
import CommonDownloadToolbar from "components/common/ui/DataGridToolbarExport";

interface FoodPurchasingGuideTableProps {}

const FoodPurchasingGuideTable: FC<FoodPurchasingGuideTableProps> = () => {
  const dishPortionOrders = useAppSelector(
    dishPortionOrdersSelector.allDishPortionOrders,
  );
  const purchasingGuide = useAppSelector(
    foodPurchasingGuideSelector.foodPurchasingGuide,
  );
  const purchasingGuideIsLoading = useAppSelector(
    foodPurchasingGuideSelector.foodPurchasingGuideIsLoading,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetPurchaseListClick = useCallback(() => {
    const portionOrders = Object.values(dishPortionOrders)
      .filter(
        (displayPortionOrder) =>
          !!displayPortionOrder.food_id && displayPortionOrder.amount > 0,
      )
      .map((portionOrder) => portionOrder);
    dispatch(
      actions.fetchFoodPurchasingGuide({
        required_dish_portion_orders: portionOrders as DishOrderPortions[],
        reserved_dish_portion_orders: undefined,
      }),
    );
  }, [dishPortionOrders, dispatch]);

  /**
   * Go to ingredient stock change details page
   * @param id The grid row ID which should be the ingredient's food ID
   */
  const handleViewDetailsClick = (id: GridRowId) => () => {
    navigate(`${Routes.INVENTORY_DETAILS}/${id}`, {
      replace: false,
      state: { foodId: id },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<FolderOpenIcon />}
            label="View details"
            className="textPrimary"
            onClick={handleViewDetailsClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "food_id", headerName: "ID", width: 60, editable: false },
    { field: "food_name", headerName: "Name", flex: 180, editable: false },
    {
      field: "required_amount",
      headerName: "Require",
      flex: 60,
      editable: false,
      valueFormatter: (value: number) => `${value.toLocaleString()} g `,
    },
    {
      field: "reserved_amount",
      headerName: "Reserved",
      flex: 60,
      editable: false,
      valueFormatter: (value: number) => `${value.toLocaleString()} g `,
    },
    {
      field: "stock_level",
      headerName: "Stock",
      flex: 60,
      editable: false,
      valueFormatter: (value: number) => `${value.toLocaleString()} g `,
    },
    {
      field: "to_buy_amount",
      headerName: "To buy",
      flex: 60,
      editable: false,
      valueFormatter: (value: number) => `${value.toLocaleString()} g `,
    },
  ];

  return (
    <>
      <EditSection>
        <CommonButton
          text="Get purchase list"
          onClick={handleGetPurchaseListClick}
        />
      </EditSection>
      <DataGridListBox>
        <DataGrid
          rows={purchasingGuide ?? undefined}
          columns={columns}
          editMode="row"
          getRowId={(row: PurchasingGuideItem) => row.food_id}
          loading={purchasingGuideIsLoading}
          slots={{
            toolbar: CommonDownloadToolbar,
          }}
        />
      </DataGridListBox>
    </>
  );
};

export default FoodPurchasingGuideTable;
