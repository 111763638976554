import { RootState } from "store/index";
import { createSelector } from "@reduxjs/toolkit";

const editingMenuLoop = (state: RootState) => state.menuLoopDetail.menuLoop;

const editingMenuLoopSpecialDates = createSelector(
  [editingMenuLoop],
  (data) => data.skip_dates,
);

const getEditingMenuLoopSpecialDate = createSelector(
  [editingMenuLoopSpecialDates],
  (data) => (key: string) => (!data ? null : data[key]),
);

const isLoading = (state: RootState) => state.menuLoopDetail.isLoading;

const error = (state: RootState) => state.menuLoopDetail.error;

export default {
  editingMenuLoop,
  editingMenuLoopSpecialDates,
  getEditingMenuLoopSpecialDate,
  isLoading,
  error,
};
