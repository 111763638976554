import {
  FoodItemTag,
  FoodTag,
  FoodTagCategory,
} from "client/jspPlatformExperiment";

export const filterIngredientLevelFoodTags = (
  foodTags: (FoodItemTag | FoodTag)[],
): (FoodItemTag | FoodTag)[] =>
  foodTags.filter(
    (tag) =>
      !(
        tag.tag_categories.includes(FoodTagCategory.DIET_TYPE) ||
        tag.tag_categories.includes(FoodTagCategory.FOOD_TEXTURE) ||
        tag.tag_categories.includes(FoodTagCategory.LIQUID_THICKNESS) ||
        (tag.tag_categories.includes(FoodTagCategory.PREFERENCE) &&
          !tag.tag_categories.includes(FoodTagCategory.ALLERGEN) &&
          !tag.tag_categories.includes(FoodTagCategory.PROTEIN))
      ),
  );

export const filterRecipeLevelFoodTags = (
  foodTags: (FoodItemTag | FoodTag)[],
): (FoodItemTag | FoodTag)[] =>
  foodTags.filter(
    (tag) =>
      tag.tag_categories.includes(FoodTagCategory.DIET_TYPE) ||
      tag.tag_categories.includes(FoodTagCategory.FOOD_TEXTURE) ||
      tag.tag_categories.includes(FoodTagCategory.LIQUID_THICKNESS) ||
      (tag.tag_categories.includes(FoodTagCategory.PREFERENCE) &&
        !tag.tag_categories.includes(FoodTagCategory.ALLERGEN) &&
        !tag.tag_categories.includes(FoodTagCategory.PROTEIN)),
  );
