import React, { FC, useCallback, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { actions } from "store/slices/order/dishPortionOrdersSlice";
import EditSection from "components/common/ui/EditSection";
import CommonButton from "components/common/ui/CommonButton";
import jspPlatformApi from "client/portals";
import foodItemOptionsSelector from "store/selectors/food/foodItemOptionsSelector";
import PortionOrderInputForm from "components/inventory/ui/purchasing/portionOrders/PortionOrderInputForm";
import { StyledDateCalendar } from "components/common/ui/Calendars";

const CenterAlignedBox = styled(Box)`
  width: inherit;
`;

interface DateRangeInputFormProps {}

const DateRangeInputForm: FC<DateRangeInputFormProps> = () => {
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>();
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>();
  const foodItemOptions = useAppSelector(
    foodItemOptionsSelector.foodDishOptions,
  );
  const dispatch = useAppDispatch();

  /**
   * Handle select from date changes
   */
  const handleSelectedFromDateChange = useCallback(
    (newValue: Dayjs) => {
      setSelectedFromDate(newValue);
      if (!selectedToDate || selectedToDate < newValue) {
        setSelectedToDate(newValue);
      }
    },
    [selectedToDate],
  );

  /**
   * Handle select to date changes
   */
  const handleSelectedToDateChange = useCallback(
    (newValue: Dayjs) => {
      setSelectedToDate(newValue);
      if (!selectedFromDate || newValue < selectedFromDate) {
        setSelectedFromDate(newValue);
      }
    },
    [selectedFromDate],
  );

  const handleGetDishOrdersClick = useCallback(() => {
    if (!selectedFromDate || !selectedToDate) {
      // error
      return;
    }

    jspPlatformApi()
      // eslint-disable-next-line max-len
      .ordering.getItemProductionNumbersOnMenusInDateRangeOrderPortionAggregatedMenuProductionDateFromDateStartToDateEndGet(
        {
          dateStart: selectedFromDate.format("YYYY-MM-DD"),
          dateEnd: selectedToDate.format("YYYY-MM-DD"),
        },
      )
      .then((dishOrders) => {
        dispatch(
          actions.replaceDishPortionOrders({
            newDishPortionOrders: dishOrders,
          }),
        );
      })
      .catch(() => {});
  }, [dispatch, selectedFromDate, selectedToDate]);

  // return <p>Date range of menus coming soon ...</p>;
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5}>
            <StyledDateCalendar
              views={["year", "month", "day"]}
              value={selectedFromDate ?? dayjs()}
              onChange={handleSelectedFromDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <CenterAlignedBox>to</CenterAlignedBox>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <StyledDateCalendar
              views={["year", "month", "day"]}
              value={selectedToDate ?? dayjs()}
              onChange={handleSelectedToDateChange}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <EditSection>
        <CommonButton
          text="Get Dish Orders"
          onClick={handleGetDishOrdersClick}
        />
      </EditSection>
      <PortionOrderInputForm />
    </>
  );
};

export default DateRangeInputForm;
