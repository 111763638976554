import React, { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, AutocompleteProps, styled } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { css } from "styled-components";
import ColoredCircularProgress from "components/common/ui/ColorCircularProgress";

type AutocompleteTextFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> & {
  label: string;
  shrink?: boolean;
  error?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactElement;
};

export const InputTextField = styled(TextField)`
  display: inline-flex;

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
` as typeof TextField;

const InlineFlexAutocomplete = styled(Autocomplete)`
  display: inline-flex;
` as typeof Autocomplete;

const AutocompleteTextField = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  label,
  shrink,
  error,
  loading,
  renderInput,
  ...props
}: AutocompleteTextFieldProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): ReactElement => {
  return (
    <InlineFlexAutocomplete
      disablePortal
      id="inline-flex-autocomplete-text-field"
      {...props}
      renderInput={
        renderInput ||
        ((params) => (
          <InputTextField
            {...params}
            label={label}
            InputLabelProps={{ shrink }}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <ColoredCircularProgress
                      color={css`rgb(200, 200, 200)`}
                      size={20}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        ))
      }
    />
  );
};

export default AutocompleteTextField;
