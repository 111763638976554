import React, { FC } from "react";
import { MenuItemBasicWithSides } from "client/jspPlatformExperiment";
import { Stack, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { GameIconsCook } from "components/common/icons/Chef";
import { IconNutrition } from "components/common/icons/Nutrition";
import styled, { css } from "styled-components";
import Icon, { IconProps } from "@mui/material/Icon";
import Typography, { TypographyProps } from "@mui/material/Typography";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

export interface StyledItemIconProps extends IconProps {
  sizepx?: number;
  positive?: boolean | undefined;
  natural?: boolean | undefined;
  danger?: boolean | undefined;
  ok?: boolean | undefined;
}

export const StyledItemIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) =>
    !["positive", "natural", "danger", "ok"].includes(prop),
})<StyledItemIconProps>`
  ${(props) => {
    let color = css`rgb(120, 203, 255)`;
    let background = css`"none"`;
    if (props.natural) {
      color = css`rgb(200, 200, 200)`;
    } else if (props.danger) {
      color = css`rgb(246, 97, 97)`;
    } else if (props.ok) {
      color = css`rgb(147, 196, 125)`;
    }
    if (props.positive) {
      const middle = background;
      background = color;
      color = middle;
    }

    return css`
      background: ${background};
      border: 0.15rem solid ${background};
      color: ${color};
    `;
  }}
`;

export interface FoodItemLabelProps extends TypographyProps {
  cost?: boolean;
  cap?: boolean;
  batch?: boolean;
}

export const FoodItemLabel = styled(Typography).withConfig({
  shouldForwardProp: (prop) => !["cost", "cap"].includes(prop),
})<FoodItemLabelProps>`
  text-align: center;
  min-width: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0 0.5rem;
  margin-left: 0.3rem;
  color: white;
  background-color: rgb(220, 220, 220);
  border-radius: 1rem;

  ${({ cost, cap, batch }) => {
    if (cost) {
      return css`
        background-color: orange;
      `;
    } else if (cap) {
      return css`
        background-color: rgb(120, 203, 255);
        padding: 0 0.3rem;
      `;
    } else if (batch) {
      return css`
        background-color: transparent;
        color: rgb(120, 203, 255);
        border: solid 0.1rem rgb(120, 203, 255);
      `;
    }
    return null;
  }}
`;

interface MenuItemViewBoxProps {
  menuItem: MenuItemBasicWithSides;
  isSide?: boolean | null;
}

const MenuItemViewBox: FC<MenuItemViewBoxProps> = ({ menuItem, isSide }) => {
  // display menu item for both main and side
  return (
    <Stack alignItems="center" direction="row">
      {isSide ? (
        <StyledItemIcon natural>
          <SubdirectoryArrowRightIcon fontSize="small" />
        </StyledItemIcon>
      ) : null}
      <Stack alignItems="left" direction="column">
        {isSide ? (
          menuItem.display_name
        ) : (
          <Stack alignItems="center" direction="row">
            <StyledItemIcon natural>
              <FiberManualRecordIcon fontSize="small" />
            </StyledItemIcon>
            {menuItem.display_name}
          </Stack>
        )}
        <Stack alignItems="center" direction="row">
          {!menuItem.food_item.checked_by_engineer_user_id ? null : (
            <Tooltip title="Engineer approved (should be safe to cook with robot)">
              <StyledItemIcon ok>
                <EngineeringIcon fontSize="small" />
              </StyledItemIcon>
            </Tooltip>
          )}
          {!menuItem.food_item.checked_by_chef_user_id ? null : (
            <Tooltip title="Chef approved (should taste good)">
              <StyledItemIcon ok>
                <GameIconsCook fontSize={18} />
              </StyledItemIcon>
            </Tooltip>
          )}
          {!menuItem.food_item.checked_by_nutritionist_user_id ? null : (
            <Tooltip title="Nutritionist approved (nutrition and food tags are accurate)">
              <StyledItemIcon ok>
                <IconNutrition fontSize={18} />
              </StyledItemIcon>
            </Tooltip>
          )}
          <Tooltip title="Portion cost">
            <FoodItemLabel cost>
              {menuItem.portion_cost || menuItem.portion_cost === 0
                ? `$ ${menuItem.portion_cost / 100}`
                : `N/A`}
            </FoodItemLabel>
          </Tooltip>
          <Tooltip title="Production #">
            <FoodItemLabel cap>{menuItem.cap}</FoodItemLabel>
          </Tooltip>
          {menuItem.food_recipe_portion ? (
            <Tooltip title="Bowl / Batch #">
              <FoodItemLabel batch>
                {Math.ceil(menuItem.cap / menuItem.food_recipe_portion)}{" "}
                bowl/batch
              </FoodItemLabel>
            </Tooltip>
          ) : null}
          <Tooltip title="Food ID">
            <FoodItemLabel>Food ID: {menuItem.food_id}</FoodItemLabel>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MenuItemViewBox;
