import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Reducer,
} from "@reduxjs/toolkit";
import { Menu } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";
import { Dayjs } from "dayjs";

interface MenusOnDateStateProps {
  menus: Menu[];
  queryDate: Dayjs | null | undefined;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: MenusOnDateStateProps = {
  menus: [],
  queryDate: null,
  isLoading: false,
  error: null,
};

/** Return value of menus data from the Jasper Platform API call */
const fetchMenusOnDate = createAsyncThunk(
  "menusOnDate/fetchMenus",
  async (queryDate: Dayjs, { signal, rejectWithValue }) => {
    const promise = jspPlatformApi().menu.getDailyMenusMenuDailyQueryDateGet({
      queryDate: queryDate.format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line no-param-reassign
    signal.onabort = () => {
      promise.cancel();
    };
    return promise
      .then((menus) => {
        return menus;
      })
      .catch((error) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      });
  },
);

const setMenus = (
  state: MenusOnDateStateProps,
  action: PayloadAction<Menu[] | null>,
): MenusOnDateStateProps => {
  if (action.payload) {
    return { ...state, menus: action.payload };
  }
  return initialState;
};

export const menusOnDateSlice = createSlice({
  name: "menuDetailSlice",
  initialState,
  reducers: {
    setMenus,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenusOnDate.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchMenusOnDate.fulfilled, (state, action) => {
      return {
        ...state,
        ...setMenus(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchMenusOnDate.rejected, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      }
      return {
        ...state,
        isLoading: false,
        error: action.payload as string,
      };
    });
  },
});

export const MenusOnDateActions = {
  ...menusOnDateSlice.actions,
  fetchMenusOnDate,
};

export const MenuOnDateReducer: Reducer<MenusOnDateStateProps> =
  menusOnDateSlice.reducer;

export default MenuOnDateReducer;
