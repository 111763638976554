import { ChangeEvent, FC, useCallback, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@mui/material";
import SpacedTextInput, {
  SpacedDatePicker,
} from "components/common/ui/SpacedTextInput";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import menuLoopDetailSelector from "store/selectors/menu/menuLoopDetailSelector";
import dayjs, { Dayjs } from "dayjs";
import { daysPerWeek } from "models/common/constants/time";
import { menuLoopActions } from "store/slices/menu/menuLoopDetailSlice";

interface MenuLoopBasicInfoEditSectionProps {}

const MenuLoopBasicInfoEditSection: FC<
  MenuLoopBasicInfoEditSectionProps
> = () => {
  const editingMenuLoop = useAppSelector(
    menuLoopDetailSelector.editingMenuLoop,
  );
  const dispatch = useAppDispatch();

  const [weeksPerCycle, setWeeksPerCycle] = useState<number | null>(
    editingMenuLoop?.days_per_cycle
      ? Math.ceil((editingMenuLoop?.days_per_cycle ?? 0) / daysPerWeek)
      : null,
  );

  /**
   * Handle menu loop basic info text input changes
   */
  const handleTextInputChanges = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const valueInput: number | string = event.target.value;
      if (editingMenuLoop) {
        dispatch(
          menuLoopActions.setMenuLoopDetail({
            ...editingMenuLoop,
            [event.target.name]: valueInput,
          }),
        );
      }
    },
    [dispatch, editingMenuLoop],
  );

  /**
   * Handle date selector change
   */
  const handleDateChange = useCallback(
    (fieldName: string) => (newValue: Dayjs | null) => {
      if (editingMenuLoop) {
        dispatch(
          menuLoopActions.setMenuLoopDetail({
            ...editingMenuLoop,
            [fieldName]: newValue?.isValid()
              ? newValue?.format("YYYY-MM-DD")
              : "",
          }),
        );
      }
    },
    [dispatch, editingMenuLoop],
  );

  const handleWeeksPerCycleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (editingMenuLoop) {
        const newValue = Number(event.target.value);
        if (newValue) {
          setWeeksPerCycle(newValue);
        } else {
          setWeeksPerCycle(null);
        }

        dispatch(
          menuLoopActions.setMenuLoopDetail({
            ...editingMenuLoop,
            days_per_cycle: (newValue ?? 0) * daysPerWeek,
          }),
        );
      }
    },

    [dispatch, editingMenuLoop],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SpacedTextInput
            label="Menu loop name"
            name="name"
            value={editingMenuLoop?.name ?? ""}
            onChange={handleTextInputChanges}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <SpacedDatePicker
            label="Repeat start date"
            value={dayjs(editingMenuLoop?.start_date ?? null)}
            onChange={handleDateChange("start_date")}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <SpacedDatePicker
            label="Repeat until date"
            value={dayjs(editingMenuLoop?.end_date ?? null)}
            onChange={handleDateChange("end_date")}
            slotProps={{
              textField: {
                helperText: `${
                  dayjs(editingMenuLoop?.end_date ?? null) <
                  dayjs(editingMenuLoop?.start_date ?? null)
                    ? "Shouldn't prior to start date."
                    : ""
                }`,
                error:
                  dayjs(editingMenuLoop?.end_date ?? null) <
                  dayjs(editingMenuLoop?.start_date ?? null),
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={3}>
          <SpacedTextInput
            label="# weeks per cycle"
            type="number"
            value={weeksPerCycle ?? ""}
            onChange={handleWeeksPerCycleChange}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default MenuLoopBasicInfoEditSection;
