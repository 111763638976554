import { RootState } from "store/index";

const menusOnDate = (state: RootState) => {
  return state.dailyMenus.menus;
};

const isMenusOnDateLoading = (state: RootState) => {
  return state.dailyMenus.isLoading;
};

const menusOnDateErrors = (state: RootState) => {
  return state.dailyMenus.error;
};

const menusOnDateSelector = {
  menusOnDate,
  isMenusOnDateLoading,
  menusOnDateErrors,
};

export default menusOnDateSelector;
