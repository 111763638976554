import React, { FC, useCallback, useState } from "react";
import SpacedTextField from "components/common/ui/SpacedTextField";
import { Grid, InputAdornment } from "@mui/material";
import { useAppSelector } from "hooks/useReduxStore";
import foodItemDetailSelector from "store/selectors/foodItem/foodItemDetailSelector";
import RecipeCostBreakdownTable from "components/food/ui/recipe/RecipeCostBreakdownTable";
import PillButton from "components/common/ui/PillButton";

interface FoodItemSenseCheckingSectionProps {}

const FoodItemSenseCheckingSection: FC<
  FoodItemSenseCheckingSectionProps
> = () => {
  const foodItem = useAppSelector(foodItemDetailSelector.foodItemFull);

  return (
    <Grid container spacing={1} columns={24}>
      <Grid item xs={12} sm={8} md={5} lg={4}>
        <SpacedTextField
          label="Portion Cost"
          name="portion_cost"
          gray
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">/portion</InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          value={
            !foodItem?.portion_cost
              ? "N/A"
              : `${Math.round(foodItem.portion_cost) / 100}`
          }
        />
      </Grid>
      {foodItem?.portion_cost_warnings ? (
        <Grid item xs={24} md={19} lg={20}>
          <SpacedTextField
            label="Cost Calculation Errors"
            name="portion_cost_warnings"
            InputLabelProps={{ shrink: true }}
            value={foodItem?.portion_cost_warnings ?? "N/A"}
            error
            multiline
            maxRows={4}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FoodItemSenseCheckingSection;
