import { FC, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ApiError, FoodQuantity } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isErrorResponseBodyType } from "models/utils/apiUtils";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import DataGridListBox from "components/common/ui/DataGridListBox";
import IngredientStockListTableToolbar from "components/inventory/ui/stockLevelList/IngredientStockListTableToolbar";

interface IngredientStockListTableProps {}

const IngredientStockListTable: FC<IngredientStockListTableProps> = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<FoodQuantity[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [keywords, setKeywords] = useState<string | undefined>();

  /**
   * Get the first batch of ingredient keys
   */
  useEffect(() => {
    jspPlatformApi()
      .inventory.searchFoodStocksInventoryStocksSearchGet({
        keywords,
        numberPerPage: paginationModel.pageSize,
        pageIndex: paginationModel.page,
        onlyProcuredItems: true,
      })
      .then((ingredientAmountRecords) => {
        setRows(ingredientAmountRecords.data);
        setRowCount(ingredientAmountRecords.total_counts ?? -1);
      })
      .catch((reason: ApiError) => {
        if (isErrorResponseBodyType(reason.body)) {
          // console.log(reason.body.detail);
        }
      });
  }, [keywords, paginationModel.page, paginationModel.pageSize]);

  /**
   * Get the first batch of ingredient keys
   */
  useEffect(() => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });
  }, [paginationModel.pageSize, keywords]);

  /**
   * Go to ingredient stock change details page
   * @param id The grid row ID which should be the ingredient's food ID
   */
  const handleViewDetailsClick = (id: GridRowId) => () => {
    navigate(`${Routes.INVENTORY_DETAILS}/${id}`, {
      replace: false,
      state: { foodId: id },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<FolderOpenIcon />}
            label="View details"
            className="textPrimary"
            onClick={handleViewDetailsClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Stock",
      width: 110,
      editable: false,
      valueFormatter: (value: number) => `${value.toLocaleString()} g `,
    },
    {
      field: "food_name",
      headerName: "Ingredient",
      flex: 180,
      editable: false,
    },
    { field: "food_id", headerName: "Food ID", width: 120, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        getRowId={(row: FoodQuantity) => row.food_id}
        slots={{
          toolbar: IngredientStockListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setKeywords,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={rowCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </DataGridListBox>
  );
};

export default IngredientStockListTable;
