import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {
  ApiError,
  FoodItemSearchingParams,
  FoodRecipeKey,
  PaginatedSearchResultsFoodRecipeKey,
} from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isErrorResponseBodyType } from "models/utils/apiUtils";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import recipeListTableToolbar from "components/food/ui/recipeList/recipeListTableToolbar";
import DataGridListBox from "components/common/ui/DataGridListBox";

// augment the props for the toolbar slot
declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    // eslint-disable-next-line
    setSearchingParams: Dispatch<SetStateAction<FoodItemSearchingParams>>;
  }
}

interface RecipeListTableProps {}

const RecipeListTable: FC<RecipeListTableProps> = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<FoodRecipeKey[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchingParams, setSearchingParams] =
    useState<FoodItemSearchingParams>({});

  /**
   * Get the first batch of recipe keys
   */
  useEffect(() => {
    jspPlatformApi()
      .recipe.advanceSearchFoodRecipesFoodRecipeSearchPost({
        requestBody: searchingParams,
        numberPerPage: paginationModel.pageSize,
        pageIndex: paginationModel.page,
        returnKeysOnly: true,
      })
      .then((results: PaginatedSearchResultsFoodRecipeKey) => {
        setRows(results.data);
        setRowCount(results.total_counts ?? -1);
      })
      .catch((reason: ApiError) => {
        if (isErrorResponseBodyType(reason.body)) {
          // console.log(reason.body.detail);
        }
      });
  }, [paginationModel.page, paginationModel.pageSize, searchingParams]);

  /**
   * Get the first batch of recipe keys
   */
  useEffect(() => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });
  }, [paginationModel.pageSize]);

  /**
   * Go to recipe detail edit page
   * @param foodId The recipe's food ID, not the recipe ID
   * @param recipeId The recipe's ID
   */
  const handleEditClick = (foodId: number, recipeId: number) => () => {
    navigate(`${Routes.FOOD_RECIPE_EDIT}/${foodId}/${recipeId}`, {
      replace: false,
      state: { editingFoodId: foodId },
    });
  };

  const columns: GridColDef<FoodRecipeKey>[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.food_id, row.id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "food_id", headerName: "ID", width: 90, editable: false },
    { field: "food_name", headerName: "Dish Name", flex: 180, editable: false },
    { field: "name", headerName: "Recipe Name", flex: 180, editable: false },
    { field: "id", headerName: "Recipe ID", width: 90, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: recipeListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setSearchingParams,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={rowCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: false,
              id: false,
            },
          },
        }}
      />
    </DataGridListBox>
  );
};

export default RecipeListTable;
