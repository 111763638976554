import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { FC } from "react";
import styled from "styled-components";
import {
  StockDepletion,
  StockReplenishment,
} from "client/jspPlatformExperiment";
import { Grid, Paper, Stack } from "@mui/material";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { sum } from "lodash";

const StyledStockRecordPaper = styled(Paper)`
  margin: 0;
  padding: 1rem;
  border-radius: 0.5rem;
  border-top: 0.1rem solid rgb(250, 250, 250);
`;

const StyledStockRecordPaperIcon = styled(Icon)`
  align-items: center;
  color: rgb(100, 100, 100);
`;

const StyledReplenishmentIcon = styled(StyledStockRecordPaperIcon)`
  font-weight: bold;
  color: white;
  background-color: rgb(0, 128, 0, 65%);
  border-radius: 0.3rem;
`;

const StyledDepletionIcon = styled(StyledReplenishmentIcon)`
  background-color: rgb(255, 0, 0, 65%);
`;

const StyledReplenishmentRow = styled(Box)`
  color: rgb(100, 100, 100);
`;

const StyledDivider = styled(Box)`
  border-bottom: solid 0.1rem rgb(240, 240, 240);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledDepletionRow = styled(StyledReplenishmentRow)`
  padding: 0 0 0.75rem 2rem;
`;

const FieldLabel = styled(Box)`
  width: fit-content;
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0.2rem 0 0 0.5rem;
  color: rgb(144, 144, 144);
`;

interface StockRecordViewBoxProps {
  stockRecord: StockReplenishment;
}

const StockRecordViewBox: FC<StockRecordViewBoxProps> = ({ stockRecord }) => {
  return (
    <StyledStockRecordPaper elevation={1}>
      <StyledReplenishmentRow>
        <Grid container spacing={1}>
          <Grid item md={3} xs={7}>
            <Stack alignItems="center" direction="row" gap={1}>
              <StyledStockRecordPaperIcon>
                <BookmarkAddIcon fontSize="small" />
              </StyledStockRecordPaperIcon>
              <FieldLabel>
                {dayjs(stockRecord.created_at).format("MMM D, YYYY HH:mm")}
              </FieldLabel>
              <StyledReplenishmentIcon>
                <NorthIcon fontSize="small" />
              </StyledReplenishmentIcon>
            </Stack>
          </Grid>
          <Grid item md={2} xs={5}>
            <Stack alignItems="center" direction="row" gap={1}>
              <FieldLabel>Remains:</FieldLabel>
              {stockRecord.amount -
                sum(stockRecord.depletion_records?.map((r) => r.amount))}{" "}
              g
            </Stack>
          </Grid>
          <Grid item md={2} xs={6}>
            <Stack alignItems="center" direction="row" gap={1}>
              <FieldLabel>Received:</FieldLabel>
              {stockRecord.amount} g
            </Stack>
          </Grid>
          <Grid item md={2} xs={6}>
            <Stack alignItems="center" direction="row" gap={1}>
              <FieldLabel>Total price:</FieldLabel>
              {`CAD$ ${stockRecord.total_price / 100}`}
            </Stack>
          </Grid>
          <Grid item md={2} xs={6}>
            <Stack alignItems="center" direction="row" gap={1}>
              <FieldLabel>Shelf life:</FieldLabel>
              {dayjs.duration(stockRecord.shelf_life).humanize()}
            </Stack>
          </Grid>
        </Grid>
      </StyledReplenishmentRow>
      {stockRecord.depletion_records &&
      stockRecord.depletion_records.length > 0 ? (
        <StyledDivider />
      ) : null}
      {stockRecord.depletion_records?.map((usageRecord: StockDepletion) => (
        <StyledDepletionRow key={`${stockRecord.id}-${usageRecord.id}`}>
          <Grid container spacing={1}>
            <Grid item md={3} xs={7}>
              <Stack alignItems="center" direction="row" gap={1}>
                <FieldLabel>
                  {dayjs(usageRecord.created_at).format("MMM D, YYYY HH:mm")}
                </FieldLabel>
                <StyledDepletionIcon>
                  <SouthIcon fontSize="small" />
                </StyledDepletionIcon>
              </Stack>
            </Grid>
            <Grid item md={3} xs={5}>
              <Stack alignItems="center" direction="row" gap={1}>
                <FieldLabel>Used:</FieldLabel>
                {usageRecord.amount} g
              </Stack>
            </Grid>
          </Grid>
        </StyledDepletionRow>
      ))}
    </StyledStockRecordPaper>
  );
};

export default StockRecordViewBox;
