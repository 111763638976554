import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import { ToolbarPropsOverrides } from "@mui/x-data-grid/models";
import { GridToolbarContainer } from "@mui/x-data-grid";
import CommonButton from "components/common/ui/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import { Grid, InputAdornment, Tooltip } from "@mui/material";
import RecipeStepGridItemBox from "components/food/ui/recipe/RecipeStepGridItemBox";
import SpacedTextInput, {
  SpacedAutocompleteInput,
} from "components/common/ui/SpacedTextInput";
import CircleIconButton from "components/common/ui/CircleIconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import SoftSquareIconButton from "components/common/ui/SoftSquareIconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import foodTagOptionsSelector from "store/selectors/food/foodTagOptionsSelector";
import { actions as foodTagOptionsActions } from "store/slices/food/foodTagOptionsSlice";
import {
  FoodItemTag,
  FoodTag,
  FoodTagCategory,
  StepType,
} from "client/jspPlatformExperiment";

const tagCategoriesRank = [
  "Diet Type",
  "Texture",
  "Liquid Thickness",
  "Preference",
];

const getFoodTagCategoryDisplayName = (
  foodTag: FoodTag | FoodItemTag,
): string => {
  let catName = "[Unknown category]";
  if (foodTag.tag_categories.includes(FoodTagCategory.DIET_TYPE)) {
    catName = `Diet Type`;
  } else if (foodTag.tag_categories.includes(FoodTagCategory.FOOD_TEXTURE)) {
    catName = `Texture`;
  } else if (
    foodTag.tag_categories.includes(FoodTagCategory.LIQUID_THICKNESS)
  ) {
    catName = `Liquid Thickness`;
  } else if (foodTag.tag_categories.includes(FoodTagCategory.PREFERENCE)) {
    catName = `Preference`;
  }
  return catName;
};

const RecipeListTableToolbar: FC<ToolbarPropsOverrides> = ({
  setSearchingParams,
}) => {
  const dispatch = useAppDispatch();
  const foodTagOptions = useAppSelector(
    foodTagOptionsSelector.recipeTagOptions,
  );
  const navigate = useNavigate();
  const [displayKeywords, setDisplayKeywords] = useState<string | undefined>();
  const [hasFoodTagsAll, setHasFoodTagsAll] = useState<FoodTag[]>([]);
  const [hasStepTypesAll, setHasStepTypesAll] = useState<
    (StepType.COOKING | StepType.OVEN | StepType.HUMAN)[]
  >([]);

  /**
   * Use effect to load the food tag option when first time loading the page
   */
  useEffect(() => {
    if (foodTagOptions.length === 0) {
      dispatch(foodTagOptionsActions.fetchAllFoodTagOptions());
    }
  }, [dispatch, foodTagOptions.length]);

  /**
   * Handle the keywords change
   * @param event
   */
  const handleKeywordsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value ?? undefined;
    setDisplayKeywords(newValue);
  };

  /**
   * Handle click on create button
   */
  const handleCreateClick = (): void => {
    navigate(Routes.FOOD_RECIPE_CREATE, {
      replace: false,
    });
  };

  /**
   * Handle click on search button
   */
  const handleSearchClick = useCallback(
    (searchingKeywords: string | undefined) => (): void => {
      setSearchingParams({
        keywords: searchingKeywords,
        has_food_tag_ids_all: hasFoodTagsAll.map((tag) => tag.id),
        has_step_types_all: hasStepTypesAll,
      });
    },
    [hasStepTypesAll, hasFoodTagsAll, setSearchingParams],
  );

  /**
   * Handle click on clear icon in searching bar
   */
  const handleClearClick = useCallback(() => {
    handleSearchClick(undefined)();
    setDisplayKeywords(undefined);
  }, [handleSearchClick]);

  /**
   * Handle keypress while focus on keyword field
   */
  const handleKeypress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleSearchClick(displayKeywords)();
      } else if (event.key === "Escape") {
        handleClearClick();
      }
    },
    [displayKeywords, handleClearClick, handleSearchClick],
  );

  /**
   * Handle the include food tags change
   */
  const handleIncludeFoodTagChange = useCallback(
    (_: SyntheticEvent, newValue: FoodTag[]) => {
      setHasFoodTagsAll(newValue);
    },
    [],
  );

  /**
   * Handle the include step types change
   */
  const handleIncludeStepTypesChange = useCallback(
    (
      _: SyntheticEvent,
      newValue: (StepType.COOKING | StepType.OVEN | StepType.HUMAN)[],
    ) => {
      setHasStepTypesAll(newValue);
    },
    [],
  );

  return (
    <GridToolbarContainer>
      <Grid container spacing={1} columns={24}>
        <Grid item xs={24} sm={24} md={18} lg={4}>
          <CommonButton
            text="Create"
            positive
            startIcon={<AddIcon />}
            onClick={handleCreateClick}
          />
        </Grid>
        <Grid item xs={24} sm={24} md={7} lg={4}>
          <RecipeStepGridItemBox>
            <SpacedAutocompleteInput
              multiple
              label="Has step (all)"
              size="small"
              options={Object.values(StepType).filter(
                (type) =>
                  type !== StepType.INGREDIENT &&
                  type !== StepType.CHILD_RECIPE,
              )}
              getOptionLabel={(option) => {
                switch (option) {
                  case StepType.COOKING:
                    return "Cooking";
                  case StepType.OVEN:
                    return "Oven";
                  case StepType.HUMAN:
                    return "Human";
                  default:
                    return "";
                }
              }}
              value={hasStepTypesAll}
              onChange={handleIncludeStepTypesChange}
            />
          </RecipeStepGridItemBox>
        </Grid>
        <Grid item xs={24} sm={24} md={17} lg={16}>
          <RecipeStepGridItemBox>
            <SpacedAutocompleteInput
              multiple
              label="Including all food tags"
              size="small"
              options={[...foodTagOptions].sort(
                (a, b) =>
                  tagCategoriesRank.findIndex(
                    (rank) => rank === getFoodTagCategoryDisplayName(a),
                  ) -
                  tagCategoriesRank.findIndex(
                    (rank) => rank === getFoodTagCategoryDisplayName(b),
                  ),
              )}
              groupBy={(option) => getFoodTagCategoryDisplayName(option)}
              getOptionLabel={(option) =>
                option.tag_name ?? `Food tag ID: ${option.id}`
              }
              value={hasFoodTagsAll}
              onChange={handleIncludeFoodTagChange}
            />
          </RecipeStepGridItemBox>
          <RecipeStepGridItemBox>
            <SpacedTextInput
              size="small"
              label="Keywords"
              InputProps={{
                endAdornment: displayKeywords ? (
                  <InputAdornment position="end">
                    <CircleIconButton
                      natural
                      sizepx={24}
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </CircleIconButton>
                  </InputAdornment>
                ) : null,
              }}
              onChange={handleKeywordsChange}
              onKeyDown={handleKeypress}
              value={displayKeywords ?? ""}
            />
            <Box>
              <Tooltip title="Search">
                <Box padding="3px 1rem 3px 0.5rem">
                  <SoftSquareIconButton
                    positive
                    sizepx={34}
                    onClick={handleSearchClick(displayKeywords)}
                  >
                    <SearchIcon />
                  </SoftSquareIconButton>
                </Box>
              </Tooltip>
            </Box>
          </RecipeStepGridItemBox>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default RecipeListTableToolbar;
