import { RootState } from "store/index";
import { createSelector } from "@reduxjs/toolkit";

const idToMenuOptionRecords = (state: RootState) => {
  return state.menuOptions.data;
};

const menuOptions = createSelector([idToMenuOptionRecords], (data) =>
  Object.values(data),
);

const isLoading = (state: RootState) => state.menuOptions.isLoading;

const error = (state: RootState) => state.menuOptions.error;

export default {
  idToMenuOptionRecords,
  menuOptions,
  isLoading,
  error,
};
