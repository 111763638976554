import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {
  ApiError,
  FoodItemKey,
  PaginatedSearchResultsFoodItemKey,
} from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isErrorResponseBodyType } from "models/utils/apiUtils";
import { useNavigate } from "react-router-dom";
import Routes from "models/routes";
import IngredientListTableToolbar from "components/food/ui/ingredientList/IngredientListTableToolbar";
import DataGridListBox from "components/common/ui/DataGridListBox";

// augment the props for the toolbar slot
declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    // eslint-disable-next-line
    setRows: any;
    setKeywords: Dispatch<SetStateAction<string | undefined>>;
  }
}

interface IngredientListTableProps {}

const IngredientListTable: FC<IngredientListTableProps> = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<FoodItemKey[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [keywords, setKeywords] = useState<string | undefined>();

  /**
   * Get the first batch of ingredient keys
   */
  useEffect(() => {
    jspPlatformApi()
      .foodItem.searchFoodItemsFoodItemSearchGet({
        keywords,
        numberPerPage: paginationModel.pageSize,
        pageIndex: paginationModel.page,
        onlyProcuredItems: true,
        returnKeysOnly: true,
      })
      .then((results: PaginatedSearchResultsFoodItemKey) => {
        setRows(results.data);
        setRowCount(results.total_counts ?? -1);
      })
      .catch((reason: ApiError) => {
        if (isErrorResponseBodyType(reason.body)) {
          // console.log(reason.body.detail);
        }
      });
  }, [keywords, paginationModel.page, paginationModel.pageSize]);

  /**
   * Get the first batch of ingredient keys
   */
  useEffect(() => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 0 });
  }, [paginationModel.pageSize, keywords]);

  /**
   * Go to ingredient detail edit page
   * @param id The grid row ID which should be the ingredient's food ID
   */
  const handleEditClick = (id: GridRowId) => () => {
    navigate(`${Routes.FOOD_INGREDIENT_EDIT}/${id}`, {
      replace: false,
      state: { editingFoodId: id },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "editAction",
      type: "actions",
      width: 45,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: "id", headerName: "ID", width: 90, editable: false },
    { field: "food_name", headerName: "Name", flex: 180, editable: false },
  ];

  return (
    <DataGridListBox>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: IngredientListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            setKeywords,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={rowCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </DataGridListBox>
  );
};

export default IngredientListTable;
