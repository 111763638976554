import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FoodItem,
  FoodItemBasic,
  FoodItemNutrition,
  FoodItemSupplierInfo,
  FoodItemTag,
  FoodRecipe,
  FoodTagCategory,
  GetFoodItemFoodItemFoodIdGetData,
  UpdateFoodItemApprovalStateFoodItemApprovalFoodIdPostData,
} from "client/jspPlatformExperiment";
import { isEmpty } from "lodash";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

interface FoodItemDetailStateProps {
  /** Return value of food item data from the Jasper Platform API call */
  foodItemFull: FoodItem | null;
  foodItem: FoodItemBasic | null;
  foodTags: FoodItemTag[];
  foodNutritionRecords: FoodItemNutrition[];
  foodRecipes: FoodRecipe[];
  foodSuppliers: FoodItemSupplierInfo[];
  isLoading: boolean;
  isApprovalChanging: boolean;
  error: string | null;
}

const initialState: FoodItemDetailStateProps = {
  foodItemFull: null,
  foodItem: null,
  foodTags: [
    { id: 17, tag_categories: [FoodTagCategory.DIET_TYPE] },
    { id: 18, tag_categories: [FoodTagCategory.DIET_TYPE] },
    { id: 19, tag_categories: [FoodTagCategory.DIET_TYPE] },
  ],
  foodNutritionRecords: [],
  foodRecipes: [],
  foodSuppliers: [],
  isLoading: false,
  isApprovalChanging: false,
  error: null,
};

const fetchFoodItemDetail = createAsyncThunk(
  "foodItemDetail/fetchFoodItemDetail",
  async (
    input: GetFoodItemFoodItemFoodIdGetData,
    { signal, rejectWithValue },
  ) => {
    try {
      const recipeFoodItemData =
        (await jspPlatformApi().foodItem.getFoodItemFoodItemFoodIdGet(
          input,
        )) as FoodItem;
      return recipeFoodItemData;
    } catch (error) {
      let errMsg: string;
      if (isApiError(error) && isErrorResponseBodyType(error.body)) {
        errMsg = error.body.detail;
      } else {
        errMsg = "Unknown error!";
      }
      return rejectWithValue(errMsg);
    }
  },
);

const updateFoodItemApprovalState = createAsyncThunk(
  "foodItemDetail/updateFoodItemApprovalState",
  async (
    input: UpdateFoodItemApprovalStateFoodItemApprovalFoodIdPostData,
    { rejectWithValue },
  ) => {
    try {
      const recipeFoodItemData =
        await jspPlatformApi().foodItem.updateFoodItemApprovalStateFoodItemApprovalFoodIdPost(
          input,
        );
      return recipeFoodItemData;
    } catch (reason) {
      let message = "Unknown error!";
      if (isApiError(reason)) {
        message = `[${reason.status}] ${reason.statusText}`;
        if (isErrorResponseBodyType(reason.body)) {
          message = reason.body.detail;
          if (reason.status === 422) {
            message = JSON.stringify(reason.body.detail);
          }
        }
      }
      return rejectWithValue(message);
    }
  },
);

const updateFoodItemDetail = (
  state: FoodItemDetailStateProps,
  action: PayloadAction<FoodItem | null>,
) => {
  const {
    food_tags: foodTags,
    food_nutrition_records: foodNutritionRecords,
    food_recipes: foodRecipes,
    food_suppliers: foodSuppliers,
    ...foodItemFull
  } = action.payload ?? { food_tags: initialState.foodTags };
  return {
    ...state,
    foodItemFull: isEmpty(foodItemFull) ? null : (foodItemFull as FoodItem),
    foodItem: isEmpty(foodItemFull) ? null : (foodItemFull as FoodItemBasic),
    foodTags: foodTags ?? [],
    foodNutritionRecords: foodNutritionRecords ?? [],
    foodRecipes: foodRecipes ?? [],
    foodSuppliers: foodSuppliers ?? [],
  };
};

export const foodItemDetailSlice = createSlice({
  name: "foodItemDetailSlice",
  initialState,
  reducers: {
    setFoodItemDetail: updateFoodItemDetail,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFoodItemDetail.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchFoodItemDetail.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodItemDetail(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchFoodItemDetail.rejected, (state, action) => {
      return { ...state, isLoading: false, error: action.payload as string };
    });
    builder.addCase(updateFoodItemApprovalState.pending, (state) => {
      return { ...state, isApprovalChanging: true };
    });
    builder.addCase(updateFoodItemApprovalState.fulfilled, (state, action) => {
      return {
        ...state,
        ...updateFoodItemDetail(state, action),
        isApprovalChanging: false,
      };
    });
    builder.addCase(updateFoodItemApprovalState.rejected, (state, action) => {
      return {
        ...state,
        isApprovalChanging: false,
        error: action.payload as string,
      };
    });
  },
});

export const actions = {
  ...foodItemDetailSlice.actions,
  fetchFoodItemDetail,
  updateFoodItemApprovalState,
};

export default foodItemDetailSlice.reducer;
