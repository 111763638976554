import {
  MenuInLoop,
  MenuInLoopCreate,
  MenuLoopCreateDeep,
  MenuLoopSpecialDateCreate,
  MenuLoopUpdateDeep,
} from "client/jspPlatformExperiment";
import {
  DisplayMenuLoop,
  DisplayMenuLoopSpecialDateBasic,
} from "models/menu/menuLoop";

export const getMenuListsAndALaCarteMenuIds = (
  daysPerCycle: number,
  menuLinks: MenuInLoop[],
) => {
  const dayGroupMenus = menuLinks.reduce((acc, link) => {
    acc.set(link.looping_day_index, [
      ...(acc.get(link.looping_day_index) ?? []),
      { menu_id: link.menu.id, serving_time: link.serving_time },
    ]);
    return acc;
  }, new Map<number, Array<MenuInLoopCreate>>());
  const menuLinksCreate = Array.from(
    { length: daysPerCycle },
    (_, i) => dayGroupMenus.get(i + 1) ?? [],
  );
  const menuLinksCreateALaCarte = dayGroupMenus.get(0) ?? [];
  return {
    menuLinksCreate,
    menuLinksCreateALaCarte,
  };
};

export const getSkipDatesCreate = (
  displaySpecialDates:
    | Record<string, DisplayMenuLoopSpecialDateBasic>
    | undefined,
) => {
  return !displaySpecialDates
    ? undefined
    : Object.values(displaySpecialDates).reduce<MenuLoopSpecialDateCreate[]>(
        (allSpecialDates, specialDate) => {
          if (specialDate.special_date) {
            return [
              ...allSpecialDates,
              {
                special_date: specialDate.special_date,
                description: specialDate.description,
              },
            ];
          }
          return allSpecialDates;
        },
        [],
      );
};

export const getMenuLoopCreateDeepFromDisplay = (
  menuLoop: DisplayMenuLoop,
): MenuLoopCreateDeep => {
  const { menuLinksCreate, menuLinksCreateALaCarte } =
    getMenuListsAndALaCarteMenuIds(
      menuLoop.days_per_cycle,
      menuLoop.menu_links,
    );
  return {
    name: menuLoop.name,
    start_date: menuLoop.start_date,
    end_date: menuLoop.end_date,
    menu_links: menuLinksCreate,
    menu_links_a_la_carte: menuLinksCreateALaCarte,
    skip_dates: getSkipDatesCreate(menuLoop.skip_dates),
  };
};

export const getMenuLoopUpdateDeepFromDisplay = (
  menuLoop: DisplayMenuLoop,
): MenuLoopUpdateDeep => {
  const { menuLinksCreate, menuLinksCreateALaCarte } =
    getMenuListsAndALaCarteMenuIds(
      menuLoop.days_per_cycle,
      menuLoop.menu_links,
    );
  return {
    name: menuLoop.name,
    start_date: menuLoop.start_date,
    end_date: menuLoop.end_date,
    menu_links: menuLinksCreate,
    menu_links_a_la_carte: menuLinksCreateALaCarte,
    skip_dates: getSkipDatesCreate(menuLoop.skip_dates),
  };
};
