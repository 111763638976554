import { Grid, InputAdornment } from "@mui/material";
import React, { FC } from "react";
import { FoodItemSupplierInfo } from "client/jspPlatformExperiment";
import { useAppSelector } from "hooks/useReduxStore";
import foodItemDetailSelector from "store/selectors/foodItem/foodItemDetailSelector";
import SpacedTextField from "components/common/ui/SpacedTextField";

interface FoodSupplierViewSectionProps {}

export const supplierFields: {
  label: string;
  name: keyof FoodItemSupplierInfo;
}[] = [
  { label: "Supplier Name", name: "supplier_id" },
  { label: "Product Code", name: "product_code" },
  { label: "Product Name", name: "product_name" },
  { label: "Pack Price", name: "pack_price" },
  { label: "Units Per Pack", name: "pack_size" },
  { label: "Unit Size (g)", name: "unit_size" },
  { label: "URL", name: "url" },
];

export const getFoodSupplierFieldAdornment = (
  name: string,
  supplierInfo: FoodItemSupplierInfo,
) => {
  switch (name) {
    case "pack_price":
      return {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      };
    case "supplier_unit_size":
      return {
        endAdornment: (
          <InputAdornment position="end">
            {supplierInfo.supplier_unit_size_uom}
          </InputAdornment>
        ),
      };
    case "unit_size":
      return {
        endAdornment: <InputAdornment position="end">G</InputAdornment>,
      };
    default:
      return undefined;
  }
};

export const getFoodSupplierFieldValue = (
  name: keyof FoodItemSupplierInfo,
  supplierInfo: FoodItemSupplierInfo,
) => {
  const priceCents = supplierInfo.pack_price;
  switch (name) {
    case "pack_price":
      return !priceCents ? "" : `${Math.round(priceCents) / 100}`;
    default:
      return supplierInfo[name] ?? "";
  }
};

const FoodSupplierViewSection: FC<FoodSupplierViewSectionProps> = () => {
  const supplierRecords = useAppSelector(foodItemDetailSelector.foodSuppliers);
  const isLoading = useAppSelector(foodItemDetailSelector.foodIsLoading);
  const supplierInfo = supplierRecords?.at(0);

  return isLoading || !supplierInfo ? (
    <div>Loading...</div>
  ) : (
    <Grid container spacing={1} columns={24}>
      {supplierFields.map((supplierTextFieldProps) => (
        <Grid
          item
          key={supplierTextFieldProps.name}
          xs={supplierTextFieldProps.name !== "url" ? 12 : 24}
          md={supplierTextFieldProps.name !== "url" ? 4 : 24}
          lg={supplierTextFieldProps.name !== "url" ? 3 : 6}
        >
          <SpacedTextField
            InputLabelProps={{ shrink: true }}
            label={supplierTextFieldProps.label}
            name={supplierTextFieldProps.name}
            value={getFoodSupplierFieldValue(
              supplierTextFieldProps.name,
              supplierInfo,
            )}
            InputProps={getFoodSupplierFieldAdornment(
              supplierTextFieldProps.name,
              supplierInfo,
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FoodSupplierViewSection;
