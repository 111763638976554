import styled, { css } from "styled-components";
import TextField from "@mui/material/TextField";
import AutocompleteTextField from "components/common/ui/AutocompleteTextField";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";
import DropDownSelect from "components/common/ui/DropDownSelect";
import SpacedTextField, {
  SpacedTextCSS,
  StyledSpacedTextField,
} from "components/common/ui/SpacedTextField";

export const SpacedInputCSS = css`
  ${SpacedTextCSS};
  background-color: transparent;

  .MuiOutlinedInput-root {
    background-color: rgb(255, 255, 255, 80%);

    &:hover fieldset {
      border-color: #ffcf84;
      border-left-width: 4px;
      padding: 4px !important; // override inline-style
    }

    &.Mui-focused fieldset {
      border-color: rgb(120, 203, 255, 50%);
    }

    &.Mui-error fieldset {
      border-color: red;
      background-color: rgb(255, 0, 0, 10%);

      legend {
        max-width: initial;
      }
    }
  }

  .MuiFormHelperText-root {
    font-weight: 500;
  }
`;

export const SpacedAutocompleteInput = styled(AutocompleteTextField)`
  ${SpacedInputCSS}
` as typeof AutocompleteTextField;

export const SpacedDatePicker = styled(DatePicker)`
  ${SpacedInputCSS}
` as typeof DatePicker;

export const SpacedTimePicker = styled(TimePicker)`
  ${SpacedInputCSS}
` as typeof TimePicker;

export const SpacedDateTimePicker = styled(DateTimePicker)`
  ${SpacedInputCSS}
` as typeof DateTimePicker;

const SpacedTextInput = styled(StyledSpacedTextField)`
  ${SpacedInputCSS}
` as typeof TextField;

export const SpacedFormControl = styled(FormControl)`
  ${SpacedInputCSS}
` as typeof FormControl;

export const SpacedDropDownSelect = styled(DropDownSelect)`
  ${SpacedInputCSS}
` as typeof DropDownSelect;

export default SpacedTextInput;
