import { MenuSectionBasicWithItems } from "client/jspPlatformExperiment";
import React, { FC } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import MenuItemViewBox from "components/menu/ui/common/MenuItemViewBox";

const StyledMenuSectionBox = styled(Box)`
  padding: 1rem 0.5rem 0 0.5rem;
`;

const MenuSectionHeader = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem 0 0.5rem;
  color: rgb(120, 203, 255);
  border-bottom: 0.1rem solid rgb(120, 203, 255);
`;

const StyledMenuMainItem = styled(Box)`
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
`;

const StyledMenuSideItem = styled(Box)`
  display: flex;
  padding-left: 1rem;
`;

interface MenuSectionViewBoxProps {
  section: MenuSectionBasicWithItems;
}

const MenuSectionViewBox: FC<MenuSectionViewBoxProps> = ({ section }) => {
  // display menu section
  return (
    <StyledMenuSectionBox>
      <MenuSectionHeader>{section.name}</MenuSectionHeader>
      {section.items.map((item) => (
        <StyledMenuMainItem key={item.id}>
          <MenuItemViewBox menuItem={item} />
          {item.sides?.map((side) => (
            <StyledMenuSideItem key={side.id}>
              <MenuItemViewBox menuItem={side} isSide />
            </StyledMenuSideItem>
          ))}
        </StyledMenuMainItem>
      ))}
    </StyledMenuSectionBox>
  );
};

export default MenuSectionViewBox;
